import React from "react";

function PrivacyPolicy() {
  return (
    <div className="min-h-screen bg-gray-50 py-12">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="bg-white rounded-xl shadow-sm p-8 space-y-8">
          {/* Header */}
          <div className="text-center mb-8">
            <h1 className="text-3xl font-bold text-gray-900">Privacy Policy</h1>
            <p className="mt-2 text-gray-600">Last Updated: January 2024</p>
          </div>

          {/* Introduction */}
          <section>
            <h2 className="text-xl font-bold text-gray-900 mb-4">
              1. Introduction
            </h2>
            <p className="text-gray-600 leading-relaxed">
              At Next Home ("nexthomecapital.com"), we are committed to
              protecting your privacy and ensuring the security of your personal
              information. This Privacy Policy explains how we collect, use,
              store, and disclose your information when you use our Website.
            </p>
          </section>

          {/* Information We Collect */}
          <section>
            <h2 className="text-xl font-bold text-gray-900 mb-4">
              2. Information We Collect
            </h2>
            <p className="text-gray-600 leading-relaxed mb-4">
              We may collect the following types of information from you:
            </p>
            <ul className="list-disc pl-6 space-y-3 text-gray-600">
              <li>
                <span className="font-semibold">Personal Information:</span>{" "}
                When you create an account, request information, or contact us,
                we may collect personal information, such as your name, email
                address, phone number, and any other information you choose to
                provide.
              </li>
              <li>
                <span className="font-semibold">Usage Data:</span> We collect
                information about how you use the Website, such as your IP
                address, browser type, access times, and the pages you view.
              </li>
              <li>
                <span className="font-semibold">
                  Cookies and Tracking Technologies:
                </span>{" "}
                We use cookies and similar tracking technologies to enhance your
                browsing experience, personalize content, and analyze usage
                patterns.
              </li>
            </ul>
          </section>

          {/* How We Use Your Information */}
          <section>
            <h2 className="text-xl font-bold text-gray-900 mb-4">
              3. How We Use Your Information
            </h2>
            <p className="text-gray-600 leading-relaxed mb-4">
              We may use the information we collect for the following purposes:
            </p>
            <ul className="list-disc pl-6 space-y-3 text-gray-600">
              <li>
                <span className="font-semibold">To Provide Services:</span> To
                provide you with access to property listings and related
                services, and to process your requests.
              </li>
              <li>
                <span className="font-semibold">To Improve the Website:</span>{" "}
                To analyze and enhance our Website's functionality and user
                experience.
              </li>
              <li>
                <span className="font-semibold">To Communicate with You:</span>{" "}
                To respond to your inquiries, provide you with updates, and send
                you information that you request.
              </li>
              <li>
                <span className="font-semibold">For Marketing Purposes:</span>{" "}
                With your consent, we may send you marketing communications
                about new properties, promotions, or updates related to our
                services.
              </li>
            </ul>
          </section>

          {/* Sharing Your Information */}
          <section>
            <h2 className="text-xl font-bold text-gray-900 mb-4">
              4. Sharing Your Information
            </h2>
            <p className="text-gray-600 leading-relaxed mb-4">
              We do not sell or rent your personal information to third parties.
              However, we may share your information with:
            </p>
            <ul className="list-disc pl-6 space-y-3 text-gray-600">
              <li>
                <span className="font-semibold">Service Providers:</span> We may
                share your information with trusted third-party service
                providers that assist us in operating the Website or providing
                services to you.
              </li>
              <li>
                <span className="font-semibold">Legal Compliance:</span> We may
                disclose your information if required by law, regulation, or
                legal process, or to protect the rights, property, or safety of
                Next Home, our users, or others.
              </li>
            </ul>
          </section>

          {/* Additional sections... */}
          <section>
            <h2 className="text-xl font-bold text-gray-900 mb-4">
              5. Cookies and Tracking Technologies
            </h2>
            <p className="text-gray-600 leading-relaxed">
              We use cookies and similar technologies to collect information
              about your interactions with the Website. You can manage your
              cookie preferences through your browser settings. Disabling
              cookies may limit your ability to use certain features of the
              Website.
            </p>
          </section>

          <section>
            <h2 className="text-xl font-bold text-gray-900 mb-4">
              6. Security
            </h2>
            <p className="text-gray-600 leading-relaxed">
              We take reasonable measures to protect your personal information
              from unauthorized access, use, or disclosure. However, please note
              that no method of transmission over the Internet is completely
              secure, and we cannot guarantee absolute security.
            </p>
          </section>

          <section>
            <h2 className="text-xl font-bold text-gray-900 mb-4">
              7. Your Rights
            </h2>
            <p className="text-gray-600 leading-relaxed">
              Depending on your location, you may have certain rights regarding
              your personal information, including the right to access, update,
              or delete your personal information, withdraw consent for
              processing, and object to certain types of data processing. To
              exercise these rights, please contact us at support@nexthome.com.
            </p>
          </section>

          <section>
            <h2 className="text-xl font-bold text-gray-900 mb-4">
              8. Children's Privacy
            </h2>
            <p className="text-gray-600 leading-relaxed">
              Our Website is not intended for use by individuals under the age
              of 18. We do not knowingly collect personal information from
              children. If we become aware that we have collected information
              from a child, we will take steps to delete it.
            </p>
          </section>

          <section>
            <h2 className="text-xl font-bold text-gray-900 mb-4">
              9. Changes to This Privacy Policy
            </h2>
            <p className="text-gray-600 leading-relaxed">
              We reserve the right to modify this Privacy Policy at any time.
              Any changes will be effective upon posting the updated Privacy
              Policy on the Website. Your continued use of the Website after
              such changes constitutes acceptance of the revised policy.
            </p>
          </section>

          <section>
            <h2 className="text-xl font-bold text-gray-900 mb-4">
              10. Contact Information
            </h2>
            <p className="text-gray-600 leading-relaxed">
              If you have any questions or concerns about this Privacy Policy,
              please contact us at support@nexthome.com
            </p>
          </section>

          <div className="text-center text-sm text-gray-500 pt-8 border-t">
            <p>Last Updated: January 2024</p>
            <p className="mt-2">© 2024 Next Home. All rights reserved.</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
