import React from "react";
import { useState, useEffect } from "react";
import { db } from "./firebase";
import { collection, getDocs } from "firebase/firestore";
import {
  Chart as ChartJS,
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Pie, Bar } from "react-chartjs-2";

// Register ChartJS components
ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function Analytics() {
  const [loading, setLoading] = useState(true);
  const [analytics, setAnalytics] = useState({
    totalRequests: 0,
    homeTypeBreakdown: {},
    sellTimelineBreakdown: {},
    occupiedStatus: {},
    listedStatus: {},
    propertyCondition: {},
    kitchenCondition: {},
    bathroomCondition: {},
    ownershipDuration: {},
    sellingReasons: {},
    monthlyRequests: {},
    totalContacts: 0,
    contactsByMonth: {},
    contactStatus: {},
    contactSources: {},
  });

  useEffect(() => {
    const fetchAnalyticsData = async () => {
      try {
        const sellRequestsSnapshot = await getDocs(
          collection(db, "homeSellerRequests")
        );
        const requests = sellRequestsSnapshot.docs.map((doc) => doc.data());

        const contactsSnapshot = await getDocs(
          collection(db, "contact_inquiries")
        );
        const contacts = contactsSnapshot.docs.map((doc) => ({
          ...doc.data(),
          timestamp: doc.data().timestamp?.toDate(),
        }));

        const data = {
          totalRequests: requests.length,
          homeTypeBreakdown: {},
          sellTimelineBreakdown: {},
          occupiedStatus: {},
          listedStatus: {},
          propertyCondition: {},
          kitchenCondition: {},
          bathroomCondition: {},
          ownershipDuration: {},
          sellingReasons: {},
          monthlyRequests: {},
          totalContacts: contacts.length,
          contactsByMonth: {},
          contactStatus: {},
          contactSources: {},
        };

        requests.forEach((request) => {
          incrementCount(data.homeTypeBreakdown, request.homeType);
          incrementCount(data.sellTimelineBreakdown, request.sellTimeline);
          incrementCount(data.occupiedStatus, request.isOccupied);
          incrementCount(data.listedStatus, request.isListed);

          incrementCount(data.propertyCondition, request.propertyCondition);
          incrementCount(data.kitchenCondition, request.kitchenCondition);
          incrementCount(data.bathroomCondition, request.bathroomCondition);
          incrementCount(data.ownershipDuration, request.ownershipDuration);
          incrementCount(data.sellingReasons, request.sellingReason);

          const month = new Date(request.createdAt).toLocaleString("default", {
            month: "long",
            year: "numeric",
          });
          incrementCount(data.monthlyRequests, month);
        });

        contacts.forEach((contact) => {
          if (contact.timestamp) {
            const month = contact.timestamp.toLocaleString("default", {
              month: "long",
              year: "numeric",
            });
            incrementCount(data.contactsByMonth, month);
          }

          incrementCount(data.contactStatus, contact.status || "New");
          incrementCount(data.contactSources, contact.source || "Website");
        });

        setAnalytics(data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching analytics:", error);
        setLoading(false);
      }
    };

    fetchAnalyticsData();
  }, []);

  const incrementCount = (obj, key) => {
    if (key) obj[key] = (obj[key] || 0) + 1;
  };

  const createChartData = (data, label) => ({
    labels: Object.keys(data),
    datasets: [
      {
        label,
        data: Object.values(data),
        backgroundColor: [
          "#4F46E5",
          "#7C3AED",
          "#EC4899",
          "#EF4444",
          "#F59E0B",
          "#10B981",
          "#3B82F6",
          "#6366F1",
          "#8B5CF6",
          "#D946EF",
        ],
        borderWidth: 1,
      },
    ],
  });

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-primary"></div>
      </div>
    );
  }

  return (
    <div className="p-6 bg-gray-50 min-h-screen">
      {/* Header */}
      <div className="max-w-7xl mx-auto mb-8">
        <div className="mb-6">
          <h2 className="text-3xl font-bold text-gray-900">
            Analytics Dashboard
          </h2>
        </div>

        {/* Key Metrics */}
        <div className="grid grid-cols-1 md:grid-cols-4 gap-6 mb-8">
          <div className="bg-white p-6 rounded-xl shadow-sm border border-gray-100">
            <h3 className="text-lg font-semibold text-gray-600 mb-2">
              Total Requests
            </h3>
            <p className="text-4xl font-bold text-primary">
              {analytics.totalRequests}
            </p>
          </div>
          <div className="bg-white p-6 rounded-xl shadow-sm border border-gray-100">
            <h3 className="text-lg font-semibold text-gray-600 mb-2">
              Most Common Home Type
            </h3>
            <p className="text-4xl font-bold text-primary">
              {Object.entries(analytics.homeTypeBreakdown).sort(
                ([, a], [, b]) => b - a
              )[0]?.[0] || "N/A"}
            </p>
          </div>
          <div className="bg-white p-6 rounded-xl shadow-sm border border-gray-100">
            <h3 className="text-lg font-semibold text-gray-600 mb-2">
              Urgent Sellers
            </h3>
            <p className="text-4xl font-bold text-primary">
              {analytics.sellTimelineBreakdown["Immediately"] || 0}
            </p>
          </div>
          <div className="bg-white p-6 rounded-xl shadow-sm border border-gray-100">
            <h3 className="text-lg font-semibold text-gray-600 mb-2">
              Total Contacts
            </h3>
            <p className="text-4xl font-bold text-primary">
              {analytics.totalContacts}
            </p>
          </div>
        </div>

        {/* Charts Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {/* Monthly Trends */}
          <div className="bg-white p-6 rounded-xl shadow-sm border border-gray-100">
            <h3 className="text-xl font-semibold mb-4 text-gray-900">
              Monthly Trends
            </h3>
            <div className="h-80">
              <Bar
                data={createChartData(analytics.monthlyRequests, "Requests")}
                options={{
                  responsive: true,
                  maintainAspectRatio: false,
                  plugins: {
                    legend: { display: false },
                  },
                }}
              />
            </div>
          </div>

          {/* Home Types */}
          <div className="bg-white p-6 rounded-xl shadow-sm border border-gray-100">
            <h3 className="text-xl font-semibold mb-4 text-gray-900">
              Home Types
            </h3>
            <div className="h-80">
              <Pie
                data={createChartData(
                  analytics.homeTypeBreakdown,
                  "Home Types"
                )}
                options={{
                  responsive: true,
                  maintainAspectRatio: false,
                }}
              />
            </div>
          </div>

          {/* Property Conditions */}
          <div className="bg-white p-6 rounded-xl shadow-sm border border-gray-100">
            <h3 className="text-xl font-semibold mb-4 text-gray-900">
              Property Conditions
            </h3>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              <div>
                <h4 className="font-medium text-gray-700 mb-2">Overall</h4>
                {Object.entries(analytics.propertyCondition).map(
                  ([key, value]) => (
                    <div
                      key={key}
                      className="flex justify-between items-center mb-2"
                    >
                      <span className="text-sm text-gray-600">{key}</span>
                      <span className="font-medium">{value}</span>
                    </div>
                  )
                )}
              </div>
              <div>
                <h4 className="font-medium text-gray-700 mb-2">Kitchen</h4>
                {Object.entries(analytics.kitchenCondition).map(
                  ([key, value]) => (
                    <div
                      key={key}
                      className="flex justify-between items-center mb-2"
                    >
                      <span className="text-sm text-gray-600">{key}</span>
                      <span className="font-medium">{value}</span>
                    </div>
                  )
                )}
              </div>
              <div>
                <h4 className="font-medium text-gray-700 mb-2">Bathroom</h4>
                {Object.entries(analytics.bathroomCondition).map(
                  ([key, value]) => (
                    <div
                      key={key}
                      className="flex justify-between items-center mb-2"
                    >
                      <span className="text-sm text-gray-600">{key}</span>
                      <span className="font-medium">{value}</span>
                    </div>
                  )
                )}
              </div>
            </div>
          </div>

          {/* Seller Circumstances */}
          <div className="bg-white p-6 rounded-xl shadow-sm border border-gray-100">
            <h3 className="text-xl font-semibold mb-4 text-gray-900">
              Seller Circumstances
            </h3>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <h4 className="font-medium text-gray-700 mb-2">Timeline</h4>
                {Object.entries(analytics.sellTimelineBreakdown).map(
                  ([key, value]) => (
                    <div
                      key={key}
                      className="flex justify-between items-center mb-2"
                    >
                      <span className="text-sm text-gray-600">{key}</span>
                      <span className="font-medium">{value}</span>
                    </div>
                  )
                )}
              </div>
              <div>
                <h4 className="font-medium text-gray-700 mb-2">
                  Ownership Duration
                </h4>
                {Object.entries(analytics.ownershipDuration).map(
                  ([key, value]) => (
                    <div
                      key={key}
                      className="flex justify-between items-center mb-2"
                    >
                      <span className="text-sm text-gray-600">{key}</span>
                      <span className="font-medium">{value}</span>
                    </div>
                  )
                )}
              </div>
            </div>
          </div>

          {/* Property Status */}
          <div className="bg-white p-6 rounded-xl shadow-sm border border-gray-100">
            <h3 className="text-xl font-semibold mb-4 text-gray-900">
              Property Status
            </h3>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <h4 className="font-medium text-gray-700 mb-2">Occupancy</h4>
                <div className="h-40">
                  <Pie
                    data={createChartData(
                      analytics.occupiedStatus,
                      "Occupancy"
                    )}
                    options={{
                      responsive: true,
                      maintainAspectRatio: false,
                    }}
                  />
                </div>
              </div>
              <div>
                <h4 className="font-medium text-gray-700 mb-2">
                  Listing Status
                </h4>
                <div className="h-40">
                  <Pie
                    data={createChartData(analytics.listedStatus, "Listed")}
                    options={{
                      responsive: true,
                      maintainAspectRatio: false,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Selling Reasons */}
          <div className="bg-white p-6 rounded-xl shadow-sm border border-gray-100">
            <h3 className="text-xl font-semibold mb-4 text-gray-900">
              Selling Reasons
            </h3>
            <div className="h-80">
              <Bar
                data={createChartData(analytics.sellingReasons, "Reasons")}
                options={{
                  responsive: true,
                  maintainAspectRatio: false,
                  indexAxis: "y",
                  plugins: {
                    legend: { display: false },
                  },
                }}
              />
            </div>
          </div>

          {/* Contact Inquiries Analysis */}
          <div className="bg-white p-6 rounded-xl shadow-sm border border-gray-100">
            <h3 className="text-xl font-semibold mb-4 text-gray-900">
              Contact Inquiries Trends
            </h3>
            <div className="h-80">
              <Bar
                data={createChartData(analytics.contactsByMonth, "Contacts")}
                options={{
                  responsive: true,
                  maintainAspectRatio: false,
                  plugins: {
                    legend: { display: false },
                  },
                }}
              />
            </div>
          </div>

          {/* Contact Status Breakdown */}
          <div className="bg-white p-6 rounded-xl shadow-sm border border-gray-100">
            <h3 className="text-xl font-semibold mb-4 text-gray-900">
              Contact Status
            </h3>
            <div className="h-80">
              <Pie
                data={createChartData(analytics.contactStatus, "Status")}
                options={{
                  responsive: true,
                  maintainAspectRatio: false,
                }}
              />
            </div>
          </div>

          {/* Combined Monthly Comparison */}
          <div className="bg-white p-6 rounded-xl shadow-sm border border-gray-100">
            <h3 className="text-xl font-semibold mb-4 text-gray-900">
              Monthly Comparison
            </h3>
            <div className="h-80">
              <Bar
                data={{
                  labels: Object.keys(analytics.monthlyRequests),
                  datasets: [
                    {
                      label: "Sell Requests",
                      data: Object.values(analytics.monthlyRequests),
                      backgroundColor: "#4F46E5",
                    },
                    {
                      label: "Contact Inquiries",
                      data: Object.values(analytics.contactsByMonth),
                      backgroundColor: "#EC4899",
                    },
                  ],
                }}
                options={{
                  responsive: true,
                  maintainAspectRatio: false,
                  plugins: {
                    legend: { display: true },
                  },
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Analytics;
