import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

function HowItWorks() {
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const position = window.pageYOffset;
      setScrollPosition(position);
    };

    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const calculateParallax = (multiplier = 0.5) => {
    return {
      transform: `translateY(${scrollPosition * multiplier}px)`,
      transition: "transform 0.1s cubic-bezier(0,0,0.2,1)",
    };
  };

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Hero Section */}
      <section className="relative h-[75vh] overflow-hidden">
        <div
          className="absolute inset-0 w-full h-[110%] top-[-5%]"
          style={calculateParallax(0.3)}
        >
          <div
            className="w-full h-full bg-cover bg-center bg-no-repeat"
            style={{
              backgroundImage: "url('/images/gold1.webp')",
              transform: "scale(1.05)",
            }}
          />
        </div>
        <div className="absolute inset-0 bg-gradient-to-br from-black/90 via-black/80 to-transparent"></div>

        {/* Hero Content */}
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24 relative z-10">
          <div className="grid md:grid-cols-2 gap-12 items-start">
            <div className="text-white pt-8">
              <h1 className="text-4xl md:text-5xl font-bold mb-6">
                Understanding Our Process
                <span className="block text-[#D4AF37] mt-2 bg-clip-text text-transparent bg-gradient-to-r from-[#D4AF37] to-[#E5C76B]">
                  Step by Step
                </span>
              </h1>
              <p className="text-xl mb-8 text-gray-200 leading-relaxed">
                We've simplified the home selling process to make it transparent
                and hassle-free. Here's exactly how we help you get from offer
                to closing.
              </p>
              <div className="flex flex-col sm:flex-row gap-4">
                <Link
                  to="/sell-your-home"
                  className="inline-block px-6 py-3 bg-gradient-to-r from-[#D4AF37] to-[#E5C76B] text-black rounded-lg hover:from-[#E5C76B] hover:to-[#D4AF37] transition duration-300 font-semibold text-center shadow-lg hover:shadow-xl transform hover:-translate-y-0.5"
                >
                  Start Your Journey
                </Link>
                <Link
                  to="/contact"
                  className="inline-block px-6 py-3 border-2 border-[#D4AF37] text-[#D4AF37] rounded-lg hover:bg-[#D4AF37]/10 transition duration-300 font-semibold text-center backdrop-blur-sm"
                >
                  Ask Questions
                </Link>
              </div>
            </div>

            {/* Timeline */}
            <div className="hidden md:block">
              <div className="relative backdrop-blur-sm bg-black/30 rounded-xl p-8 border border-white/10">
                {[
                  "Request Received",
                  "Offer Generated",
                  "Options Presented",
                  "Closing Process",
                  "Keys Handed Over",
                ].map((step, index) => (
                  <div key={index} className="flex items-center mb-8 last:mb-0">
                    <div className="h-12 w-12 rounded-full bg-gradient-to-br from-[#D4AF37] to-[#E5C76B] text-black flex items-center justify-center font-bold shadow-lg">
                      {index + 1}
                    </div>
                    <div className="ml-4 bg-white/5 backdrop-blur-sm rounded-lg px-6 py-4 border border-[#D4AF37]/20 shadow-lg hover:border-[#D4AF37]/40 transition-all duration-300 w-full">
                      <span className="text-[#D4AF37] font-medium text-lg">
                        {step}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Benefits Section - Enhanced Design */}
      <section className="py-20 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl md:text-4xl font-bold text-center mb-6 text-gray-900">
            Why Choose Our Process?
            <span className="block text-[#D4AF37] mt-2 bg-clip-text text-transparent bg-gradient-to-r from-[#D4AF37] to-[#E5C76B]">
              Simple. Fast. Reliable.
            </span>
          </h2>
          <div className="grid md:grid-cols-3 gap-8 mt-12">
            {[
              {
                title: "Transparent Timeline",
                description:
                  "Know exactly what happens at each step of the process",
                icon: "⏱️",
              },
              {
                title: "No Hidden Fees",
                description: "Get a clear breakdown of all costs upfront",
                icon: "💰",
              },
              {
                title: "Dedicated Support",
                description:
                  "Work with the same expert throughout your journey",
                icon: "🤝",
              },
            ].map((benefit, index) => (
              <div
                key={index}
                className="bg-white rounded-xl p-8 shadow-sm hover:shadow-md transition-all duration-300 border border-gray-100 hover:border-[#D4AF37]/30"
              >
                <div className="text-4xl mb-4">{benefit.icon}</div>
                <h3 className="text-xl font-semibold text-gray-900 mb-3">
                  {benefit.title}
                </h3>
                <p className="text-gray-600">{benefit.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Detailed Steps Section - Enhanced Design */}
      <section className="py-20 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl md:text-4xl font-bold text-center mb-6 text-gray-900">
            Your Journey to Selling
            <span className="block text-[#D4AF37] mt-2 bg-clip-text text-transparent bg-gradient-to-r from-[#D4AF37] to-[#E5C76B]">
              Step by Step Guide
            </span>
          </h2>
          <div className="space-y-12 mt-12">
            {[
              {
                title: "Initial Contact",
                description:
                  "Share your property details and get a preliminary estimate",
                details: [
                  "Quick online form",
                  "Basic property information",
                  "Instant acknowledgment",
                ],
              },
              {
                title: "Offer Generation",
                description:
                  "Receive your personalized cash offer within 24 hours",
                details: [
                  "Market analysis",
                  "Property valuation",
                  "Clear offer terms",
                ],
              },
              {
                title: "Closing Process",
                description: "Choose your timeline and complete the sale",
                details: [
                  "Flexible closing dates",
                  "Minimal paperwork",
                  "Quick cash transfer",
                ],
              },
            ].map((step, index) => (
              <div key={index} className="relative">
                <div className="grid md:grid-cols-3 gap-8 items-center">
                  <div className="md:col-span-1">
                    <div className="flex items-center">
                      <div className="h-12 w-12 rounded-full bg-gradient-to-r from-[#D4AF37] to-[#E5C76B] text-black flex items-center justify-center font-bold text-xl shadow-lg">
                        {index + 1}
                      </div>
                      <h3 className="text-xl font-bold text-gray-900 ml-4">
                        {step.title}
                      </h3>
                    </div>
                  </div>
                  <div className="md:col-span-2">
                    <p className="text-gray-600 mb-4">{step.description}</p>
                    <ul className="grid grid-cols-1 sm:grid-cols-3 gap-4">
                      {step.details.map((detail, i) => (
                        <li
                          key={i}
                          className="flex items-center text-sm text-gray-500"
                        >
                          <svg
                            className="h-5 w-5 text-[#D4AF37] mr-2"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M5 13l4 4L19 7"
                            />
                          </svg>
                          {detail}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                {index < 2 && (
                  <div className="hidden md:block absolute left-[2.8rem] top-[4rem] bottom-[-2rem] w-0.5 bg-gradient-to-b from-[#D4AF37]/30 to-[#E5C76B]/30"></div>
                )}
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="relative h-[60vh] overflow-hidden">
        <div className="absolute inset-0">
          <div
            className="w-full h-full bg-cover bg-center bg-no-repeat"
            style={{
              backgroundImage: "url('/images/gold1.webp')",
            }}
          />
        </div>
        <div className="absolute inset-0 bg-gradient-to-br from-black/80 via-black/70 to-black/60"></div>

        {/* CTA Content */}
        <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 text-center relative z-10 h-full flex flex-col justify-center">
          <h2 className="text-3xl md:text-4xl font-bold mb-8 bg-clip-text text-transparent bg-gradient-to-r from-[#D4AF37] to-[#E5C76B]">
            Ready to Experience Our Simple Process?
          </h2>
          <p className="text-xl text-gray-200 mb-10">
            Get started today and receive your cash offer within 24 hours.
          </p>
          <div className="flex flex-col sm:flex-row gap-4 justify-center">
            <Link
              to="/sell-your-home"
              className="px-8 py-4 bg-gradient-to-r from-[#D4AF37] to-[#E5C76B] text-black rounded-lg hover:from-[#E5C76B] hover:to-[#D4AF37] transition duration-300 font-semibold shadow-lg hover:shadow-xl transform hover:-translate-y-0.5"
            >
              Get Your Cash Offer
            </Link>
            <Link
              to="/contact"
              className="px-8 py-4 border-2 border-[#D4AF37] text-[#D4AF37] rounded-lg hover:bg-[#D4AF37]/10 transition duration-300 font-semibold backdrop-blur-sm"
            >
              Learn More
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
}

export default HowItWorks;
