import React, { createContext, useContext, useState, useEffect } from "react";
import { auth } from "./firebase"; // Adjust this import based on your firebase setup

const AuthContext = createContext();

export function AuthProvider({ children }) {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        // You might want to fetch additional user data here, like isAdmin status
        setUser({ ...user, isAdmin: true }); // Set isAdmin based on your logic
      } else {
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, []);

  return (
    <AuthContext.Provider value={{ user }}>{children}</AuthContext.Provider>
  );
}

export function useAuth() {
  return useContext(AuthContext);
}
