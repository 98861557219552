import React, { useState } from "react";
import {
  PhoneIcon,
  EnvelopeIcon,
  MapPinIcon,
  ClockIcon,
  UserIcon,
} from "@heroicons/react/24/outline";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { db } from "./firebase";

function ContactPage() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });
  const [status, setStatus] = useState({ type: "", message: "" });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setStatus({ type: "", message: "" });

    try {
      await addDoc(collection(db, "contact_inquiries"), {
        ...formData,
        timestamp: serverTimestamp(),
        status: "new",
      });

      setStatus({
        type: "success",
        message: "Thank you for your message. We will get back to you soon!",
      });
      setFormData({ name: "", email: "", phone: "", message: "" });
    } catch (error) {
      setStatus({
        type: "error",
        message: "There was an error sending your message. Please try again.",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Updated Hero Section with layered textures */}
      <div className="relative bg-gradient-to-br from-black via-[#111111] to-black">
        {/* Base noise texture */}
        <div
          className="absolute inset-0 opacity-[0.4]"
          style={{
            backgroundImage: `url("data:image/svg+xml,%3Csvg viewBox='0 0 300 300' xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='noiseFilter'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='0.9' numOctaves='4' stitchTiles='stitch'/%3E%3C/filter%3E%3Crect width='100%' height='100%' filter='url(%23noiseFilter)'/%3E%3C/svg%3E")`,
            backgroundRepeat: "repeat",
            mixBlendMode: "soft-light",
          }}
        />

        {/* Diagonal grid */}
        <div
          className="absolute inset-0 opacity-[0.15]"
          style={{
            backgroundImage: `url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 60L60 0H30L0 30M60 60V30L30 60' stroke='%23D4AF37' stroke-width='0.8' fill='none' /%3E%3C/svg%3E")`,
            backgroundSize: "60px 60px",
          }}
        />

        {/* Gold dust effect */}
        <div
          className="absolute inset-0 opacity-[0.08]"
          style={{
            backgroundImage: `url("data:image/svg+xml,%3Csvg width='80' height='80' viewBox='0 0 80 80' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23D4AF37' fill-opacity='0.5'%3E%3Ccircle cx='40' cy='40' r='0.8'/%3E%3Ccircle cx='20' cy='60' r='0.6'/%3E%3Ccircle cx='60' cy='20' r='0.7'/%3E%3Ccircle cx='10' cy='10' r='0.5'/%3E%3Ccircle cx='70' cy='70' r='0.5'/%3E%3C/g%3E%3C/svg%3E")`,
            backgroundSize: "80px 80px",
          }}
        />

        {/* Depth gradient */}
        <div
          className="absolute inset-0 bg-gradient-to-b from-black/60 via-transparent to-black/60"
          style={{
            mixBlendMode: "overlay",
          }}
        />

        <div className="max-w-7xl mx-auto px-4 py-20 sm:px-6 lg:px-8 relative">
          <div className="text-center">
            <h1 className="text-4xl font-bold sm:text-5xl">
              <span className="text-white">Get in Touch</span>
              <span className="block mt-2 bg-gradient-to-r from-[#D4AF37] to-[#E5C76B] bg-clip-text text-transparent">
                We're Here to Help
              </span>
            </h1>
            <p className="mt-4 text-xl text-gray-300">
              We're here to help you with any questions about selling your home
            </p>
          </div>
        </div>
      </div>

      {/* Contact Information */}
      <div className="max-w-7xl mx-auto px-4 py-16 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 mb-16">
          <div className="bg-white p-8 rounded-xl shadow-sm hover:shadow-md transition-shadow duration-300">
            <div className="flex items-center mb-4">
              <div className="h-12 w-12 bg-[#D4AF37] rounded-full flex items-center justify-center">
                <PhoneIcon className="h-6 w-6 text-white" />
              </div>
              <h3 className="ml-4 text-xl font-semibold text-gray-900">
                Call Us
              </h3>
            </div>
            <p className="text-gray-600">(480) 608-9481</p>
          </div>

          <div className="bg-white p-8 rounded-xl shadow-sm hover:shadow-md transition-shadow duration-300">
            <div className="flex items-center mb-4">
              <div className="h-12 w-12 bg-[#D4AF37] rounded-full flex items-center justify-center">
                <EnvelopeIcon className="h-6 w-6 text-white" />
              </div>
              <h3 className="ml-4 text-xl font-semibold text-gray-900">
                Email
              </h3>
            </div>
            <p className="text-gray-600">support@nexthomecapital.com</p>
          </div>

          <div className="bg-white p-8 rounded-xl shadow-sm hover:shadow-md transition-shadow duration-300">
            <div className="flex items-center mb-4">
              <div className="h-12 w-12 bg-[#D4AF37] rounded-full flex items-center justify-center">
                <MapPinIcon className="h-6 w-6 text-white" />
              </div>
              <h3 className="ml-4 text-xl font-semibold text-gray-900">
                Office
              </h3>
            </div>
            <p className="text-gray-600">
              9393 N 90th St, Suite 102, Scottsdale, AZ 85258
            </p>
          </div>

          <div className="bg-white p-8 rounded-xl shadow-sm hover:shadow-md transition-shadow duration-300">
            <div className="flex items-center mb-4">
              <div className="h-12 w-12 bg-[#D4AF37] rounded-full flex items-center justify-center">
                <ClockIcon className="h-6 w-6 text-white" />
              </div>
              <h3 className="ml-4 text-xl font-semibold text-gray-900">
                Hours
              </h3>
            </div>
            <p className="text-gray-600">Mon-Fri: 9AM - 6PM EST</p>
          </div>
        </div>

        {/* Contact Form - Updated to match sell steps style */}
        <div className="max-w-2xl mx-auto">
          <div className="text-center mb-10">
            <h2 className="text-3xl font-bold text-gray-900 mb-4">
              Send us a Message
            </h2>
            <p className="text-lg text-gray-600">
              We'll get back to you as soon as possible
            </p>
          </div>

          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="relative">
              <label
                htmlFor="name"
                className="block text-sm font-medium text-gray-700 mb-2"
              >
                Full Name
              </label>
              <div className="relative rounded-lg shadow-sm">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <UserIcon className="h-5 w-5 text-gray-400" />
                </div>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={(e) =>
                    setFormData((prev) => ({ ...prev, name: e.target.value }))
                  }
                  className="block w-full pl-10 pr-4 py-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-accent focus:border-transparent transition duration-150"
                  placeholder="John Doe"
                  required
                />
              </div>
            </div>

            <div className="relative">
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700 mb-2"
              >
                Email Address
              </label>
              <div className="relative rounded-lg shadow-sm">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <EnvelopeIcon className="h-5 w-5 text-gray-400" />
                </div>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={(e) =>
                    setFormData((prev) => ({ ...prev, email: e.target.value }))
                  }
                  className="block w-full pl-10 pr-4 py-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-accent focus:border-transparent transition duration-150"
                  placeholder="john@example.com"
                  required
                />
              </div>
            </div>

            <div className="relative">
              <label
                htmlFor="phone"
                className="block text-sm font-medium text-gray-700 mb-2"
              >
                Phone Number
              </label>
              <div className="relative rounded-lg shadow-sm">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <PhoneIcon className="h-5 w-5 text-gray-400" />
                </div>
                <input
                  type="tel"
                  id="phone"
                  name="phone"
                  value={formData.phone}
                  onChange={(e) =>
                    setFormData((prev) => ({ ...prev, phone: e.target.value }))
                  }
                  className="block w-full pl-10 pr-4 py-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-accent focus:border-transparent transition duration-150"
                  placeholder="(555) 123-4567"
                  required
                />
              </div>
            </div>

            <div className="relative">
              <label
                htmlFor="message"
                className="block text-sm font-medium text-gray-700 mb-2"
              >
                Message
              </label>
              <textarea
                id="message"
                name="message"
                rows={4}
                value={formData.message}
                onChange={(e) =>
                  setFormData((prev) => ({ ...prev, message: e.target.value }))
                }
                className="block w-full px-4 py-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-accent focus:border-transparent transition duration-150"
                placeholder="How can we help you?"
                required
              />
            </div>

            <div className="mt-8 pt-6 border-t border-gray-200">
              <button
                type="submit"
                disabled={isSubmitting}
                className="w-full px-8 py-3 bg-[#D4AF37] text-white rounded-lg hover:bg-opacity-90 transition duration-300 font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#D4AF37]"
              >
                {isSubmitting ? "Sending..." : "Send Message"}
              </button>
            </div>

            {status.message && (
              <div
                className={`mt-4 p-4 rounded-lg ${
                  status.type === "success"
                    ? "bg-green-50 text-green-800"
                    : "bg-red-50 text-red-800"
                }`}
              >
                {status.message}
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  );
}

export default ContactPage;
