import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import HomePage from "./HomePage";
import SellYourHome from "./SellYourHome";
import ContactPage from "./ContactPage";
import Login from "./Login";
import AdminDashboard from "./AdminDashboard";
import Analytics from "./Analytics";
import AdminSellRequests from "./AdminSellRequests";
import AddressStreetView from "./AddressStreetView";
import Navbar from "./Navbar";
import { AuthProvider, useAuth } from "./AuthContext";
import Footer from "./components/shared/Footer";
import HowItWorks from "./HowItWorks";
import TermsAndConditions from "./TermsAndConditions";
import PrivacyPolicy from "./PrivacyPolicy";
import AdminManageUsers from "./AdminManageUsers";
import ChatBot from "./components/ChatBot";
import AdminContactInquiries from "./AdminContactInquiries";
import AdminSettings from "./AdminSettings";
import AdminContent from "./AdminContent";

// Protected Route Component for Admin Access
const AdminRoute = ({ children }) => {
  const auth = useAuth();
  if (!auth || !auth.user || !auth.user.isAdmin) {
    return <Navigate to="/login" replace />;
  }
  return children;
};

function App() {
  return (
    <AuthProvider>
      <Router>
        <div className="flex flex-col min-h-screen">
          <Navbar />
          <main className="flex-grow pt-16">
            <Routes>
              {/* Public Routes */}
              <Route path="/" element={<HomePage />} />
              <Route
                path="/addressstreetview"
                element={<AddressStreetView />}
              />

              {/* Sell Your Home Flow */}
              <Route path="/sell-your-home/*" element={<SellYourHome />} />

              {/* Other Public Routes */}
              <Route path="/contact" element={<ContactPage />} />
              <Route path="/login" element={<Login />} />

              {/* Admin Routes */}
              <Route
                path="/admin-dashboard"
                element={
                  <AdminRoute>
                    <AdminDashboard />
                  </AdminRoute>
                }
              />
              <Route
                path="/admin/analytics"
                element={
                  <AdminRoute>
                    <Analytics />
                  </AdminRoute>
                }
              />
              <Route
                path="/admin/sell-requests"
                element={
                  <AdminRoute>
                    <AdminSellRequests />
                  </AdminRoute>
                }
              />
              <Route
                path="/admin/manage-users"
                element={
                  <AdminRoute>
                    <AdminManageUsers />
                  </AdminRoute>
                }
              />
              <Route
                path="/admin/contact-inquiries"
                element={
                  <AdminRoute>
                    <AdminContactInquiries />
                  </AdminRoute>
                }
              />
              <Route
                path="/admin/settings"
                element={
                  <AdminRoute>
                    <AdminSettings />
                  </AdminRoute>
                }
              />
              <Route
                path="/admin/content"
                element={
                  <AdminRoute>
                    <AdminContent />
                  </AdminRoute>
                }
              />

              {/* Catch all route - redirect to home */}
              <Route path="*" element={<Navigate to="/" replace />} />

              {/* How It Works Route */}
              <Route path="/how-it-works" element={<HowItWorks />} />

              {/* Terms and Conditions Route */}
              <Route path="/terms" element={<TermsAndConditions />} />

              {/* Privacy Policy Route */}
              <Route path="/privacy" element={<PrivacyPolicy />} />
            </Routes>
          </main>
          <Footer />
          <ChatBot />
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;
