import React, { useState, useEffect } from "react";
import {
  collection,
  getDocs,
  doc,
  updateDoc,
  addDoc,
  deleteDoc,
} from "firebase/firestore";
import { db } from "./firebase";
import {
  PencilIcon,
  TrashIcon,
  PlusIcon,
} from "@heroicons/react/24/outline";

function AdminContent() {
  const [sections, setSections] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editingId, setEditingId] = useState(null);
  const [showAddModal, setShowAddModal] = useState(false);
  const [error, setError] = useState("");

  const [newContent, setNewContent] = useState({
    title: "",
    content: "",
    section: "home", // default section
    lastUpdated: new Date().toISOString(),
    status: "published",
  });

  // Sections for the dropdown
  const sectionOptions = [
    { value: "home", label: "Home Page" },
    { value: "about", label: "About Us" },
    { value: "services", label: "Services" },
    { value: "faq", label: "FAQ" },
    { value: "contact", label: "Contact" },
  ];

  useEffect(() => {
    fetchContent();
  }, []);

  const fetchContent = async () => {
    try {
      const contentRef = collection(db, "content");
      const snapshot = await getDocs(contentRef);
      const contentData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setSections(contentData);
    } catch (error) {
      console.error("Error fetching content:", error);
      setError("Failed to load content");
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = (content) => {
    setEditingId(content.id);
    setNewContent(content);
  };

  const handleSave = async (id) => {
    try {
      const contentRef = doc(db, "content", id);
      await updateDoc(contentRef, {
        ...newContent,
        lastUpdated: new Date().toISOString(),
      });

      setSections(
        sections.map((section) =>
          section.id === id ? { ...section, ...newContent } : section
        )
      );

      setEditingId(null);
      setError("");
    } catch (error) {
      console.error("Error updating content:", error);
      setError("Failed to update content");
    }
  };

  const handleAdd = async (e) => {
    e.preventDefault();
    try {
      const contentRef = collection(db, "content");
      const docRef = await addDoc(contentRef, {
        ...newContent,
        lastUpdated: new Date().toISOString(),
      });

      setSections([...sections, { id: docRef.id, ...newContent }]);
      setShowAddModal(false);
      setNewContent({
        title: "",
        content: "",
        section: "home",
        status: "published",
      });
      setError("");
    } catch (error) {
      console.error("Error adding content:", error);
      setError("Failed to add content");
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this content?")) {
      try {
        await deleteDoc(doc(db, "content", id));
        setSections(sections.filter((section) => section.id !== id));
        setError("");
      } catch (error) {
        console.error("Error deleting content:", error);
        setError("Failed to delete content");
      }
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-50 pt-20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-primary mx-auto"></div>
            <p className="mt-2 text-gray-600">Loading content...</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 pt-20">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Header */}
        <div className="flex justify-between items-center mb-8">
          <h1 className="text-3xl font-bold text-gray-900">
            Content Management
          </h1>
          <button
            onClick={() => setShowAddModal(true)}
            className="flex items-center px-4 py-2 bg-primary text-white rounded-lg hover:bg-primary/90"
          >
            <PlusIcon className="w-5 h-5 mr-2" />
            Add Content
          </button>
        </div>

        {error && (
          <div className="mb-4 p-4 bg-red-100 text-red-700 rounded-lg">
            {error}
          </div>
        )}

        {/* Content Grid */}
        <div className="grid gap-6">
          {sections.map((content) => (
            <div
              key={content.id}
              className="bg-white rounded-xl shadow-sm p-6 border border-gray-100"
            >
              {editingId === content.id ? (
                // Edit Mode
                <div className="space-y-4">
                  <input
                    type="text"
                    value={newContent.title}
                    onChange={(e) =>
                      setNewContent({ ...newContent, title: e.target.value })
                    }
                    className="w-full px-3 py-2 border border-gray-300 rounded-lg"
                    placeholder="Title"
                  />
                  <select
                    value={newContent.section}
                    onChange={(e) =>
                      setNewContent({ ...newContent, section: e.target.value })
                    }
                    className="w-full px-3 py-2 border border-gray-300 rounded-lg"
                  >
                    {sectionOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                  <textarea
                    value={newContent.content}
                    onChange={(e) =>
                      setNewContent({ ...newContent, content: e.target.value })
                    }
                    className="w-full px-3 py-2 border border-gray-300 rounded-lg h-32"
                    placeholder="Content"
                  />
                  <div className="flex justify-end gap-2">
                    <button
                      onClick={() => {
                        setEditingId(null);
                        setNewContent({
                          title: "",
                          content: "",
                          section: "home",
                          status: "published",
                        });
                      }}
                      className="px-4 py-2 border border-gray-300 rounded-lg hover:bg-gray-50"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={() => handleSave(content.id)}
                      className="px-4 py-2 bg-primary text-white rounded-lg hover:bg-primary/90"
                    >
                      Save
                    </button>
                  </div>
                </div>
              ) : (
                // View Mode
                <div>
                  <div className="flex justify-between items-start mb-4">
                    <div>
                      <h2 className="text-xl font-semibold text-gray-900">
                        {content.title}
                      </h2>
                      <p className="text-sm text-gray-500">
                        Section:{" "}
                        {
                          sectionOptions.find(
                            (opt) => opt.value === content.section
                          )?.label
                        }
                      </p>
                    </div>
                    <div className="flex gap-2">
                      <button
                        onClick={() => handleEdit(content)}
                        className="p-2 text-blue-600 hover:bg-blue-50 rounded-lg"
                      >
                        <PencilIcon className="w-5 h-5" />
                      </button>
                      <button
                        onClick={() => handleDelete(content.id)}
                        className="p-2 text-red-600 hover:bg-red-50 rounded-lg"
                      >
                        <TrashIcon className="w-5 h-5" />
                      </button>
                    </div>
                  </div>
                  <p className="text-gray-600 whitespace-pre-wrap">
                    {content.content}
                  </p>
                  <p className="text-sm text-gray-400 mt-4">
                    Last updated:{" "}
                    {new Date(content.lastUpdated).toLocaleString()}
                  </p>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>

      {/* Add Content Modal */}
      {showAddModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
          <div className="bg-white rounded-xl p-6 w-full max-w-md">
            <h2 className="text-2xl font-bold mb-4">Add New Content</h2>
            <form onSubmit={handleAdd} className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Title
                </label>
                <input
                  type="text"
                  required
                  value={newContent.title}
                  onChange={(e) =>
                    setNewContent({ ...newContent, title: e.target.value })
                  }
                  className="w-full px-3 py-2 border border-gray-300 rounded-lg"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Section
                </label>
                <select
                  required
                  value={newContent.section}
                  onChange={(e) =>
                    setNewContent({ ...newContent, section: e.target.value })
                  }
                  className="w-full px-3 py-2 border border-gray-300 rounded-lg"
                >
                  {sectionOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Content
                </label>
                <textarea
                  required
                  value={newContent.content}
                  onChange={(e) =>
                    setNewContent({ ...newContent, content: e.target.value })
                  }
                  className="w-full px-3 py-2 border border-gray-300 rounded-lg h-32"
                />
              </div>
              <div className="flex justify-end gap-2">
                <button
                  type="button"
                  onClick={() => {
                    setShowAddModal(false);
                    setNewContent({
                      title: "",
                      content: "",
                      section: "home",
                      status: "published",
                    });
                  }}
                  className="px-4 py-2 border border-gray-300 rounded-lg hover:bg-gray-50"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 bg-primary text-white rounded-lg hover:bg-primary/90"
                >
                  Add Content
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}

export default AdminContent;
