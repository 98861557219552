import React from "react";

function TermsAndConditions() {
  return (
    <div className="min-h-screen bg-gray-50 py-12">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="bg-white rounded-xl shadow-sm p-8 space-y-8">
          <div className="text-center mb-8">
            <h1 className="text-3xl font-bold text-gray-900">
              Terms and Conditions
            </h1>
            <p className="mt-2 text-gray-600">Last Updated: January 2024</p>
          </div>

          {/* Introduction */}
          <section>
            <h2 className="text-xl font-bold text-gray-900 mb-4">
              1. Introduction
            </h2>
            <p className="text-gray-600 leading-relaxed">
              Welcome to Next Home ("nexthomecapital.com"). These terms and
              conditions ("Terms") govern your use of our Website, including all
              services, tools, and information made available through it. By
              accessing or using our Website, you agree to comply with and be
              bound by these Terms. If you do not agree to these Terms, you must
              not use the Website.
            </p>
          </section>

          {/* Website Use */}
          <section>
            <h2 className="text-xl font-bold text-gray-900 mb-4">
              2. Website Use
            </h2>
            <p className="text-gray-600 leading-relaxed">
              The Website is intended to provide information about real estate
              properties, including listings, property details, and related
              services. All content provided on the Website is for informational
              purposes only and should not be construed as professional real
              estate advice. You are solely responsible for verifying the
              accuracy of any information found here before making any real
              estate decisions.
            </p>
          </section>

          {/* User Accounts */}
          <section>
            <h2 className="text-xl font-bold text-gray-900 mb-4">
              3. User Accounts
            </h2>
            <p className="text-gray-600 leading-relaxed">
              Certain areas of the Website may require you to create an account.
              You agree to provide accurate, current, and complete information
              when creating your account. You are responsible for maintaining
              the confidentiality of your login credentials and for all
              activities that occur under your account. If you suspect any
              unauthorized use of your account, please notify us immediately.
            </p>
          </section>

          {/* Property Listings */}
          <section>
            <h2 className="text-xl font-bold text-gray-900 mb-4">
              4. Property Listings
            </h2>
            <p className="text-gray-600 leading-relaxed">
              Property listings provided on the Website are subject to change
              without notice. We do not guarantee the accuracy, completeness, or
              availability of any property details listed. All listings are
              subject to prior sale, price changes, or withdrawal without
              notice. It is your responsibility to verify the details of any
              property that you are interested in.
            </p>
          </section>

          {/* Intellectual Property Rights */}
          <section>
            <h2 className="text-xl font-bold text-gray-900 mb-4">
              5. Intellectual Property Rights
            </h2>
            <p className="text-gray-600 leading-relaxed">
              All content on the Website, including text, graphics, images,
              logos, and software, is the intellectual property of Next Home or
              its licensors. Unauthorized use of the content is prohibited. You
              may not reproduce, distribute, or create derivative works from any
              material found on the Website without express written permission.
            </p>
          </section>

          {/* Prohibited Conduct */}
          <section>
            <h2 className="text-xl font-bold text-gray-900 mb-4">
              6. Prohibited Conduct
            </h2>
            <p className="text-gray-600 leading-relaxed">
              When using the Website, you agree not to:
            </p>
            <ul className="list-disc pl-6 mt-2 space-y-2 text-gray-600">
              <li>Violate any applicable laws or regulations</li>
              <li>
                Post or transmit any unauthorized or harmful content, including
                viruses or malicious software
              </li>
              <li>
                Engage in any activity that could impair the proper functioning
                of the Website
              </li>
              <li>Use the Website for any unlawful or fraudulent purpose</li>
            </ul>
          </section>

          {/* Limitation of Liability */}
          <section>
            <h2 className="text-xl font-bold text-gray-900 mb-4">
              7. Limitation of Liability
            </h2>
            <p className="text-gray-600 leading-relaxed">
              To the fullest extent permitted by law, Next Home shall not be
              liable for any direct, indirect, incidental, or consequential
              damages resulting from your use or inability to use the Website.
              We do not warrant that the Website will be error-free or that any
              content will be accurate or reliable.
            </p>
          </section>

          {/* Links to Third-Party Websites */}
          <section>
            <h2 className="text-xl font-bold text-gray-900 mb-4">
              8. Links to Third-Party Websites
            </h2>
            <p className="text-gray-600 leading-relaxed">
              The Website may contain links to third-party websites. These links
              are provided for your convenience only. We have no control over
              the content of these websites and are not responsible for their
              practices or policies. You access these third-party websites at
              your own risk.
            </p>
          </section>

          {/* Privacy Policy */}
          <section>
            <h2 className="text-xl font-bold text-gray-900 mb-4">
              9. Privacy Policy
            </h2>
            <p className="text-gray-600 leading-relaxed">
              Your use of the Website is also governed by our Privacy Policy. By
              using the Website, you consent to the collection and use of your
              information as described in our Privacy Policy.
            </p>
          </section>

          {/* Changes to Terms */}
          <section>
            <h2 className="text-xl font-bold text-gray-900 mb-4">
              10. Changes to Terms
            </h2>
            <p className="text-gray-600 leading-relaxed">
              We reserve the right to modify these Terms at any time. Any
              changes will be effective upon posting the updated Terms on the
              Website. Your continued use of the Website after such changes
              constitutes acceptance of the revised Terms.
            </p>
          </section>

          {/* Governing Law */}
          <section>
            <h2 className="text-xl font-bold text-gray-900 mb-4">
              11. Governing Law
            </h2>
            <p className="text-gray-600 leading-relaxed">
              These Terms are governed by and construed in accordance with the
              laws of Arizona. Any disputes arising from the use of the Website
              shall be subject to the exclusive jurisdiction of the courts in
              Arizona.
            </p>
          </section>

          {/* Contact Information */}
          <section>
            <h2 className="text-xl font-bold text-gray-900 mb-4">
              12. Contact Information
            </h2>
            <p className="text-gray-600 leading-relaxed">
              If you have any questions about these Terms, please contact us at
              support@nexthome.com
            </p>
          </section>

          <div className="text-center text-sm text-gray-500 pt-8 border-t">
            <p>Last Updated: January 2024</p>
            <p className="mt-2">© 2024 Next Home. All rights reserved.</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TermsAndConditions;
