export const sendNewSellRequestEmail = async (requestData) => {
  try {
    // Using relative path with proxy configuration
    const apiUrl = '/api/send-email';

    console.log('Request data:', JSON.stringify(requestData, null, 2)); // Debug log
    
    // Remove any undefined or null values
    const cleanedData = Object.fromEntries(
      Object.entries(requestData).filter(([_, v]) => v != null)
    );
    
    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(cleanedData),
    });

    let responseData;
    const contentType = response.headers.get('content-type');
    
    console.log('Response status:', response.status); // Debug log
    console.log('Content-Type:', contentType); // Debug log

    if (contentType && contentType.includes('application/json')) {
      responseData = await response.json();
    } else {
      const textResponse = await response.text();
      console.error('Raw response:', textResponse); // Debug log
      throw new Error(`Server error: ${response.status} ${response.statusText}`);
    }

    if (!response.ok) {
      throw new Error(responseData.error || `Failed to send email: ${response.status} ${response.statusText}`);
    }

    console.log('Email sent successfully:', responseData); // Debug log
    return responseData;
  } catch (error) {
    console.error('Error sending email notification:', error);
    throw error;
  }
};

export const sendNewContactInquiryEmail = async (inquiryData) => {
  try {
    const apiUrl = '/api/send-email';
    
    // Remove any undefined or null values
    const cleanedData = Object.fromEntries(
      Object.entries(inquiryData).filter(([_, v]) => v != null)
    );
    
    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ...cleanedData,
        type: 'contact_inquiry' // Add type to differentiate from sell requests
      }),
    });

    let responseData;
    const contentType = response.headers.get('content-type');
    
    console.log('Response status:', response.status);
    console.log('Content-Type:', contentType);

    if (contentType && contentType.includes('application/json')) {
      responseData = await response.json();
    } else {
      const textResponse = await response.text();
      console.error('Raw response:', textResponse);
      throw new Error(`Server error: ${response.status} ${response.statusText}`);
    }

    if (!response.ok) {
      throw new Error(responseData.error || `Failed to send email: ${response.status} ${response.statusText}`);
    }

    console.log('Email sent successfully:', responseData);
    return responseData;
  } catch (error) {
    console.error('Error sending email notification:', error);
    throw error;
  }
};