import React, { useState, useRef, useEffect } from "react";
import { XMarkIcon, PaperAirplaneIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";

function ChatBot() {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [messages, setMessages] = useState([
    {
      type: "bot",
      content:
        "Hi! I'm here to help you sell your home. What would you like to know?",
    },
  ]);
  const [inputMessage, setInputMessage] = useState("");
  const messagesEndRef = useRef(null);
  const [isVisible, setIsVisible] = useState(true);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const handleSendMessage = async (e) => {
    e.preventDefault();
    if (!inputMessage.trim()) return;

    setMessages((prev) => [
      ...prev,
      { type: "user", content: inputMessage.trim() },
    ]);

    const response = getSimpleResponse(inputMessage.toLowerCase());

    setTimeout(() => {
      setMessages((prev) => [
        ...prev,
        { type: "bot", content: response.message },
      ]);
      if (response.action) {
        setTimeout(() => {
          navigate(response.action);
          setIsOpen(false);
        }, 1000);
      }
    }, 1000);

    setInputMessage("");
  };

  const getSimpleResponse = (message) => {
    if (
      message.includes("sell") ||
      message.includes("offer") ||
      message.includes("cash") ||
      message.includes("price")
    ) {
      return {
        message:
          "I can help you get started with selling your home. Let me take you to our home selling process.",
        action: "/sell-your-home",
      };
    }

    if (
      message.includes("contact") ||
      message.includes("speak") ||
      message.includes("talk") ||
      message.includes("call") ||
      message.includes("representative") ||
      message.includes("team")
    ) {
      return {
        message: "I'll connect you with our team right away.",
        action: "/contact",
      };
    }

    if (message.includes("process") || message.includes("how")) {
      return {
        message:
          "Our process is simple: 1. Share your home details 2. Get a cash offer 3. Close on your timeline. Would you like to start the process now?",
        action: "/sell-your-home",
      };
    }

    if (
      message.includes("time") ||
      message.includes("long") ||
      message.includes("when")
    ) {
      return {
        message:
          "We can close in as little as 7 days, or on your preferred timeline. Would you like to speak with our team about your specific situation?",
        action: "/contact",
      };
    }

    if (
      message.includes("yes") ||
      message.includes("sure") ||
      message.includes("okay")
    ) {
      return {
        message: "Great! Let me connect you with our team.",
        action: "/contact",
      };
    }

    return {
      message:
        "I'd be happy to help you with that. Would you like to speak with our team or learn more about selling your home?",
      action: null,
    };
  };

  const toggleChatbot = () => {
    setIsVisible(!isVisible);
  };

  return (
    <div className="fixed bottom-4 right-4 z-50">
      {/* Chat Button */}
      <button
        onClick={toggleChatbot}
        className={`bg-transparent text-accent/70 p-4 rounded-full shadow-lg hover:bg-accent/10 transition-all duration-300 ${
          isOpen ? "hidden" : "block"
        }`}
      >
        <svg
          className="w-6 h-6"
          fill="none"
          stroke="currentColor"
          strokeWidth={1}
          viewBox="0 0 24 24"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            style={{ stroke: "#D4AF37" }}
            d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z"
          />
        </svg>
      </button>

      {/* Chat Window */}
      {isVisible && (
        <div className="fixed bottom-4 right-4 w-80 sm:w-96 bg-black/95 rounded-lg shadow-xl border border-accent/30">
          {/* Header */}
          <div className="bg-gradient-to-r from-accent to-accent-light text-black p-4 rounded-t-lg flex justify-between items-center">
            <h3 className="font-semibold">Next Home Assistant</h3>
            <button
              onClick={toggleChatbot}
              className="hover:bg-black/10 p-1 rounded-full transition-colors"
            >
              <XMarkIcon className="w-6 h-6" />
            </button>
          </div>

          {/* Messages */}
          <div className="h-96 overflow-y-auto p-4 space-y-4">
            {messages.map((message, index) => (
              <div
                key={index}
                className={`flex ${
                  message.type === "user" ? "justify-end" : "justify-start"
                }`}
              >
                <div
                  className={`max-w-[80%] p-3 rounded-lg ${
                    message.type === "user"
                      ? "bg-accent text-black"
                      : "bg-white/10 text-white"
                  }`}
                >
                  {message.content}
                </div>
              </div>
            ))}
            <div ref={messagesEndRef} />
          </div>

          {/* Input */}
          <form
            onSubmit={handleSendMessage}
            className="p-4 border-t border-accent/30"
          >
            <div className="flex space-x-2">
              <input
                type="text"
                value={inputMessage}
                onChange={(e) => setInputMessage(e.target.value)}
                placeholder="Type your message..."
                className="flex-1 p-2 border border-accent/30 rounded-lg focus:outline-none focus:ring-2 focus:ring-accent bg-black/50 text-white placeholder-gray-400"
              />
              <button
                type="submit"
                className="bg-accent text-black p-2 rounded-lg hover:bg-accent-light transition-colors"
              >
                <PaperAirplaneIcon className="w-5 h-5" />
              </button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
}

export default ChatBot;
