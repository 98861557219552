import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  ClipboardDocumentListIcon,
  CurrencyDollarIcon,
  CalendarIcon,
} from "@heroicons/react/24/outline";
import { StarIcon } from "@heroicons/react/24/solid";
import { useIntersectionObserver } from "./hooks/useIntersectionObserver";

// Updated color palette
const colors = {
  primary: "#2C3E50",
  secondary: "#34495E",
  accent: "#D4AF37",
  light: "#ECF0F1",
  white: "#FFFFFF",
  text: "#333333",
};

// Add these patterns near the top with your colors object
const patterns = {
  dots: "url(\"data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%239C92AC' fill-opacity='0.05' fill-rule='evenodd'%3E%3Ccircle cx='3' cy='3' r='3'/%3E%3Ccircle cx='13' cy='13' r='3'/%3E%3C/g%3E%3C/svg%3E\")",
  grid: "url(\"data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%239C92AC' fill-opacity='0.05' fill-rule='evenodd'%3E%3Cpath d='M0 40L40 0H20L0 20M40 40V20L20 40'/%3E%3C/g%3E%3C/svg%3E\")",
};

function HomePage() {
  const [scrollY, setScrollY] = useState(0);
  const whyChooseUsRef = useRef(null);
  const howItWorksRef = useRef(null);
  const testimonialsRef = useRef(null);
  const [address, setAddress] = useState("");
  const navigate = useNavigate();

  const whyChooseUsVisible = useIntersectionObserver(whyChooseUsRef, {
    threshold: 0.1,
  });
  const howItWorksVisible = useIntersectionObserver(howItWorksRef, {
    threshold: 0.1,
  });
  const testimonialsVisible = useIntersectionObserver(testimonialsRef, {
    threshold: 0.1,
  });

  useEffect(() => {
    const handleScroll = () => setScrollY(window.pageYOffset);
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleAddressSubmit = (e) => {
    e.preventDefault();
    if (address.trim()) {
      // Update to navigate to the address section with the address
      navigate("/sell-your-home", {
        state: { address: address.trim() },
      });
    }
  };

  const renderStars = () => (
    <div className="flex mb-2">
      {[...Array(5)].map((_, i) => (
        <StarIcon key={i} className="h-5 w-5 text-yellow-400" />
      ))}
    </div>
  );

  return (
    <div className="font-sans text-text">
      {/* Navigation Bar */}

      {/* Updated Hero Section */}
      <section className="relative h-screen overflow-hidden">
        <div
          className="absolute inset-0 bg-cover bg-center"
          style={{
            backgroundImage: "url('/images/hero20.webp')",
            opacity: 0.8,
          }}
        />
        <div className="absolute inset-0 bg-gradient-to-r from-gray-800 to-transparent opacity-70" />
        <div
          className="absolute inset-0 flex items-center z-10"
          style={{
            transform: `translateY(${scrollY * 0.5}px)`,
          }}
        >
          <div className="container mx-auto px-6">
            <div className="max-w-2xl text-white">
              <h1 className="text-5xl font-bold mb-4">
                Get a Cash Offer and Move On Your Terms
              </h1>
              <p className="text-xl mb-8">
                Skip the hassle of listings and showings. Get a competitive cash
                offer for your home today.
              </p>

              {/* Hero Section Address Form */}
              <form onSubmit={handleAddressSubmit} className="space-y-4">
                <div className="flex flex-col sm:flex-row gap-4">
                  <input
                    type="text"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    placeholder="Enter your home address"
                    className="flex-grow px-6 py-4 rounded-full text-gray-800 text-lg focus:outline-none focus:ring-2 focus:ring-accent"
                    required
                  />
                  <button
                    type="submit"
                    className="bg-[#D4AF37] text-white px-8 py-4 rounded-full hover:bg-opacity-90 transition duration-300 text-lg font-semibold whitespace-nowrap"
                  >
                    Get Your Offer
                  </button>
                </div>
                <p className="text-sm text-white opacity-80 text-center sm:text-left">
                  Find out how much you can get for your home in minutes
                </p>
              </form>
            </div>
          </div>
        </div>
      </section>

      {/* How It Works Section (moved up) */}
      <section
        ref={howItWorksRef}
        className={`py-20 bg-light transition-opacity duration-1000 relative ${
          howItWorksVisible ? "opacity-100" : "opacity-0"
        }`}
        style={{
          backgroundImage: patterns.dots,
          backgroundColor: colors.light,
        }}
      >
        <div className="container mx-auto px-4">
          <h2 className="text-4xl font-bold text-center mb-16 text-primary">
            Get a cash offer in a few short steps
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-10">
            {[
              {
                icon: ClipboardDocumentListIcon,
                title: "1. Share Your Details",
                description:
                  "Provide us with some basic information about yourself and the property you're looking to sell.",
              },
              {
                icon: CurrencyDollarIcon,
                title: "2. Skip showings",
                description:
                  "Do it all from your phone. No need for time-consuming open houses or multiple showings.",
              },
              {
                icon: CalendarIcon,
                title: "3. Move on your schedule",
                description:
                  "Get paid in days. Close the deal and receive your cash offer quickly and conveniently.",
              },
            ].map((item, index) => (
              <div
                key={index}
                className={`bg-white p-8 rounded-xl shadow-lg hover:shadow-xl transition duration-300 transform ${
                  howItWorksVisible
                    ? "translate-y-0 opacity-100"
                    : "translate-y-10 opacity-0"
                }`}
                style={{
                  transitionDelay: `${index * 200}ms`,
                  transitionProperty: "transform, opacity",
                  transitionDuration: "1000ms",
                }}
              >
                <item.icon className="w-20 h-20 mb-6 text-[#D4AF37]" />
                <h3 className="text-2xl font-semibold mb-4 text-primary">
                  {item.title}
                </h3>
                <p className="text-gray-600 leading-relaxed">
                  {item.description}
                </p>
              </div>
            ))}
          </div>
          <div className="text-center mt-12">
            <Link
              to="/how-it-works"
              className="inline-flex items-center bg-[#D4AF37] text-white px-8 py-3 rounded-full hover:bg-opacity-90 transition duration-300"
            >
              Learn More
              <svg
                className="w-5 h-5 ml-2"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M9 5l7 7-7 7"
                />
              </svg>
            </Link>
          </div>
        </div>
      </section>

      {/* Why Choose Us Section */}
      <section
        ref={whyChooseUsRef}
        className={`py-20 transition-opacity duration-1000 relative ${
          whyChooseUsVisible ? "opacity-100" : "opacity-0"
        }`}
        style={{
          backgroundColor: colors.primary,
          backgroundImage: patterns.grid,
        }}
      >
        <div className="container mx-auto px-4">
          <h2 className="text-4xl font-bold text-center mb-16 text-white">
            Why Choose Us?
          </h2>
          <h3 className="text-2xl font-bold text-center mb-12 text-accent">
            THE BENEFITS OF WORKING WITH US
          </h3>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-10">
            {[
              {
                title: "QUICK CLOSE",
                description:
                  "Our partnership with an outstanding group of investors enables us to purchase your property in less than 7 days.",
                icon: "M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z", // Clock icon path
              },
              {
                title: "WE PAY CLOSING FEES",
                description:
                  "When you sell with us, we help reduce your closing fees, unlike traditional property sales.",
                icon: "M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5z", // Document with dollar icon path
              },
              {
                title: "No Commissions",
                description:
                  "Sellers pay no commissions when working directly with us, even though we collaborate with realtors frequently.",
                icon: "M21.75 9v.906a2.25 2.25 0 01-1.183 1.981l-6.478 3.488M2.25 9v.906a2.25 2.25 0 001.183 1.981l6.478 3.488m8.839 2.51l-4.66-2.51m0 0l-1.023-.55a2.25 2.25 0 00-2.134 0l-1.022.55m0 0l-4.661 2.51m16.5 1.615a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V8.844a2.25 2.25 0 011.183-1.98l7.5-4.04a2.25 2.25 0 012.134 0l7.5 4.04a2.25 2.25 0 011.183 1.98V19.5z", // Envelope icon path
              },
              {
                title: "Flexible Closing Date",
                description:
                  "We buy houses on your terms, allowing for a stress-free and flexible closing date.",
                icon: "M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5", // Calendar icon path
              },
              {
                title: "Alternative Options",
                description:
                  "We offer multiple ways to collaborate, so reach out to explore how we can assist you.",
                icon: "M19.5 12c0-1.232-.046-2.453-.138-3.662a4.006 4.006 0 00-3.7-3.7 48.678 48.678 0 00-7.324 0 4.006 4.006 0 00-3.7 3.7c-.017.22-.032.441-.046.662M19.5 12l3-3m-3 3l-3-3m-12 3c0 1.232.046 2.453.138 3.662a4.006 4.006 0 003.7 3.7 48.656 48.656 0 007.324 0 4.006 4.006 0 003.7-3.7c.017-.22.032-.441.046-.662M4.5 12l3 3m-3-3l-3 3", // Options icon path
              },
              {
                title: "TENANT OCCUPIED PROPERTY",
                description:
                  "Enjoy a seamless transition with our expertise in managing tenant-occupied properties.",
                icon: "M2.25 21h19.5m-18-18v18m10.5-18v18m6-13.5V21M6.75 6.75h.75m-.75 3h.75m-.75 3h.75m3-6h.75m-.75 3h.75m-.75 3h.75M6.75 21v-3.375c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21M3 3h12m-.75 4.5H21m-3.75 3.75h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008z", // Building icon path
              },
            ].map((item, index) => (
              <div
                key={index}
                className="group bg-white p-8 rounded-xl shadow-lg hover:shadow-2xl transition-all duration-300 transform hover:-translate-y-2"
                style={{
                  animationDelay: `${index * 0.2}s`,
                }}
              >
                <div className="mb-6 relative">
                  <div className="w-16 h-16 bg-accent/10 rounded-lg flex items-center justify-center group-hover:bg-accent/20 transition-colors duration-300">
                    <svg
                      className="w-8 h-8 text-accent group-hover:scale-110 transition-transform duration-300"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d={item.icon}
                      />
                    </svg>
                  </div>
                </div>
                <h3 className="text-2xl font-semibold mb-4 text-primary group-hover:text-[#D4AF37] transition-colors duration-300">
                  {item.title}
                </h3>
                <p className="text-gray-600 leading-relaxed">
                  {item.description}
                </p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Testimonials Section */}
      <section
        ref={testimonialsRef}
        className={`py-20 bg-light transition-opacity duration-1000 ${
          testimonialsVisible ? "opacity-100" : "opacity-0"
        }`}
      >
        <div className="container mx-auto px-4">
          <h2 className="text-4xl font-bold text-center mb-16 text-primary">
            What Our Clients Say
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-10">
            {[
              {
                name: "John D.",
                location: "Florida",
                quote:
                  "I was in a pre-foreclosure situation and thought I was going to walk away with nothing. They helped me by buying my home easy and hassle-free. They helped me walk away with some money for my next home. 100% would recommend!",
              },
              {
                name: "Michael Thompson",
                location: "Atlanta, GA",
                quote:
                  "I needed to sell quickly due to a job relocation. They made it possible to sell my home without any repairs or showings. The offer was competitive and the process was transparent.",
              },
            ].map((testimonial, index) => (
              <div
                key={index}
                className="bg-white p-8 rounded-xl shadow-lg hover:shadow-xl transition duration-300"
              >
                {renderStars()}
                <div className="mt-4">
                  <p className="mb-4 text-gray-600 italic leading-relaxed">
                    "{testimonial.quote}"
                  </p>
                  <p className="font-semibold text-lg text-primary">
                    {testimonial.name}
                  </p>
                  <p className="text-sm text-gray-500">
                    Sold their home in {testimonial.location}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Call to Action */}
      <section className="relative py-20 text-white">
        <div
          className="absolute inset-0 bg-cover bg-center"
          style={{
            backgroundImage: "url('/images/home2.webp')",
          }}
        />
        {/* Add a dark overlay to ensure text readability */}
        <div className="absolute inset-0 bg-black opacity-60" />

        <div className="container mx-auto px-4 text-center relative z-10">
          <h2 className="text-4xl font-bold mb-6">
            Ready to Start Your Real Estate Journey?
          </h2>
          <p className="text-xl mb-10 max-w-2xl mx-auto">
            Let's find your perfect property investment together.
          </p>
          <Link
            to="/contact"
            className="bg-[#D4AF37] text-white px-8 py-3 rounded-full hover:bg-opacity-90 transition duration-300 inline-block"
          >
            Contact Us Today
          </Link>
        </div>
      </section>
    </div>
  );
}

export default HomePage;
