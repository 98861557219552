import React from "react";
import { CheckCircleIcon } from "@heroicons/react/24/solid";

function KitchenConditionSection({ formData, updateFormData, onNext, onBack }) {
  const handleSubmit = (e) => {
    e.preventDefault();
    onNext();
  };

  const kitchenTypes = [
    {
      id: "fixer",
      title: "Fixer Upper",
      description: "Kitchen needs significant repairs",
      image: "/images/fixer.webp",
    },
    {
      id: "dated",
      title: "Dated",
      description: "Kitchen hasn't been updated recently",
      image: "/images/dated.webp",
    },
    {
      id: "standard",
      title: "Standard",
      description: "Kitchen is updated with common finishes",
      image: "/images/standard.webp",
    },
    {
      id: "highend",
      title: "High End",
      description: "Kitchen has high-quality upgrades",
      image: "/images/highend.webp",
    },
    {
      id: "luxury",
      title: "Luxury",
      description: "Kitchen has elegant, top-tier finishes",
      image: "/images/luxury.webp",
    },
  ];

  return (
    <div className="max-w-4xl mx-auto">
      <div className="text-center mb-10">
        <h2 className="text-3xl font-bold text-gray-900 mb-4">
          How would you describe your kitchen?
        </h2>
        <p className="text-lg text-gray-600">
          Select the option that best matches your kitchen's condition
        </p>
      </div>

      <form onSubmit={handleSubmit} className="space-y-8">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {kitchenTypes.map((type) => (
            <div
              key={type.id}
              className={`group relative rounded-xl overflow-hidden shadow-sm transition-all duration-300 transform hover:-translate-y-1 hover:shadow-lg cursor-pointer ${
                formData.kitchenCondition === type.id
                  ? "ring-2 ring-accent"
                  : "hover:ring-1 hover:ring-accent"
              }`}
              onClick={() => updateFormData({ kitchenCondition: type.id })}
            >
              <div className="aspect-w-16 aspect-h-12 relative">
                <img
                  src={type.image}
                  alt={type.title}
                  className="w-full h-48 object-cover"
                />
                {formData.kitchenCondition === type.id && (
                  <div className="absolute top-2 right-2">
                    <CheckCircleIcon className="h-8 w-8 text-accent" />
                  </div>
                )}
                <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent opacity-0 group-hover:opacity-100 transition-opacity" />
              </div>

              <div className="p-4 bg-white">
                <h3 className="font-semibold text-lg text-gray-900 mb-2">
                  {type.title}
                </h3>
                <p className="text-gray-600 text-sm leading-relaxed">
                  {type.description}
                </p>
              </div>

              <div
                className={`absolute inset-0 border-2 rounded-xl transition-opacity ${
                  formData.kitchenCondition === type.id
                    ? "border-accent opacity-100"
                    : "border-transparent opacity-0"
                }`}
              />
            </div>
          ))}
        </div>

        <div className="flex justify-between pt-8">
          <button
            type="button"
            onClick={onBack}
            className="px-8 py-3 border-2 border-gray-300 text-gray-700 rounded-lg hover:bg-gray-50 transition duration-300 font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
          >
            Back
          </button>
          <button
            type="submit"
            disabled={!formData.kitchenCondition}
            className="px-8 py-3 bg-accent text-white rounded-lg hover:bg-opacity-90 transition duration-300 font-medium disabled:opacity-50 disabled:cursor-not-allowed focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-accent"
          >
            Continue
          </button>
        </div>
      </form>
    </div>
  );
}

export default KitchenConditionSection;
