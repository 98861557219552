import React from "react";
import { HomeIcon, BuildingOfficeIcon } from "@heroicons/react/24/outline";

function PropertyDetailsSection({ formData, updateFormData, onNext, onBack }) {
  const handleSubmit = (e) => {
    e.preventDefault();
    onNext();
  };

  const homeTypes = [
    {
      value: "Single Family Home",
      icon: HomeIcon,
      description: "Detached single-family residence",
    },
    {
      value: "Condo",
      icon: BuildingOfficeIcon,
      description: "Unit in a multi-unit building",
    },
    {
      value: "Townhouse",
      icon: HomeIcon,
      description: "Attached multi-level home",
    },
    {
      value: "Multi-Family",
      icon: BuildingOfficeIcon,
      description: "2-4 unit residential building",
    },
    {
      value: "Mobile Home",
      icon: HomeIcon,
      description: "Manufactured or mobile home",
    },
    {
      value: "Other",
      icon: HomeIcon,
      description: "Other property type",
    },
  ];

  const propertyConditions = [
    {
      value: "Excellent",
      label: "Excellent - Move-in ready",
      description: "Property is in pristine condition with modern updates",
    },
    {
      value: "Good",
      label: "Good - Minor repairs needed",
      description: "Property needs minor cosmetic updates",
    },
    {
      value: "Fair",
      label: "Fair - Some repairs needed",
      description: "Property needs moderate repairs and updates",
    },
    {
      value: "Poor",
      label: "Poor - Major repairs needed",
      description: "Property needs significant repairs and renovation",
    },
  ];

  return (
    <div className="max-w-2xl mx-auto">
      <div className="text-center mb-10">
        <h2 className="text-3xl font-bold text-gray-900 mb-4">
          Tell us about your property
        </h2>
        <p className="text-lg text-gray-600">
          Help us understand more about your property type and condition
        </p>
      </div>

      <form onSubmit={handleSubmit} className="space-y-8">
        <div className="space-y-4">
          <label className="block text-lg font-medium text-gray-900 mb-4">
            Property Type
          </label>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {homeTypes.map((type) => (
              <div
                key={type.value}
                className={`relative rounded-lg border-2 p-4 cursor-pointer transition-all duration-200 ${
                  formData.homeType === type.value
                    ? "border-accent bg-accent/5"
                    : "border-gray-200 hover:border-accent/50"
                }`}
                onClick={() => updateFormData({ homeType: type.value })}
              >
                <div className="flex items-start space-x-3">
                  <div
                    className={`flex-shrink-0 mt-1 ${
                      formData.homeType === type.value
                        ? "text-accent"
                        : "text-gray-400"
                    }`}
                  >
                    <type.icon className="h-6 w-6" />
                  </div>
                  <div>
                    <p className="font-medium text-gray-900">{type.value}</p>
                    <p className="text-sm text-gray-500">{type.description}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="space-y-4">
          <label className="block text-lg font-medium text-gray-900 mb-4">
            Property Condition
          </label>
          <div className="space-y-3">
            {propertyConditions.map((condition) => (
              <div
                key={condition.value}
                className={`relative rounded-lg border-2 p-4 cursor-pointer transition-all duration-200 ${
                  formData.propertyCondition === condition.value
                    ? "border-accent bg-accent/5"
                    : "border-gray-200 hover:border-accent/50"
                }`}
                onClick={() =>
                  updateFormData({ propertyCondition: condition.value })
                }
              >
                <div>
                  <p className="font-medium text-gray-900">{condition.label}</p>
                  <p className="text-sm text-gray-500">
                    {condition.description}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="flex justify-between pt-8">
          <button
            type="button"
            onClick={onBack}
            className="px-8 py-3 border-2 border-gray-300 text-gray-700 rounded-lg hover:bg-gray-50 transition duration-300 font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
          >
            Back
          </button>
          <button
            type="submit"
            disabled={!formData.homeType || !formData.propertyCondition}
            className="px-8 py-3 bg-accent text-white rounded-lg hover:bg-opacity-90 transition duration-300 font-medium disabled:opacity-50 disabled:cursor-not-allowed focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-accent"
          >
            Continue
          </button>
        </div>
      </form>
    </div>
  );
}

export default PropertyDetailsSection;
