import React, { useState, useEffect } from "react";
import {
  collection,
  query,
  orderBy,
  getDocs,
  deleteDoc,
  doc,
} from "firebase/firestore";
import { db } from "./firebase";
import {
  EnvelopeIcon,
  PhoneIcon,
  ClockIcon,
  ChatBubbleLeftIcon,
  MagnifyingGlassIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";

function AdminContactInquiries() {
  const [inquiries, setInquiries] = useState([]);
  const [loading, setLoading] = useState(true);
  const [expandedInquiry, setExpandedInquiry] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterStatus, setFilterStatus] = useState("all");
  const [sortBy, setSortBy] = useState("timestamp");
  const [sortOrder, setSortOrder] = useState("desc");
  const [deleteConfirm, setDeleteConfirm] = useState(null);

  const ITEMS_PER_PAGE = 10;

  useEffect(() => {
    fetchInquiries();
  }, []);

  const fetchInquiries = async () => {
    try {
      const q = query(
        collection(db, "contact_inquiries"),
        orderBy("timestamp", "desc")
      );
      const querySnapshot = await getDocs(q);
      const inquiriesData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
        timestamp: doc.data().timestamp?.toDate(),
      }));
      setInquiries(inquiriesData);
    } catch (error) {
      console.error("Error fetching inquiries:", error);
    } finally {
      setLoading(false);
    }
  };

  // Filter and sort inquiries
  const filteredInquiries = inquiries
    .filter((inquiry) => {
      const matchesSearch =
        inquiry.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        inquiry.email?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        inquiry.message?.toLowerCase().includes(searchTerm.toLowerCase());

      const matchesStatus =
        filterStatus === "all" || inquiry.status === filterStatus;

      return matchesSearch && matchesStatus;
    })
    .sort((a, b) => {
      const aValue = a[sortBy];
      const bValue = b[sortBy];
      return sortOrder === "desc" ? bValue - aValue : aValue - bValue;
    });

  // Calculate pagination
  const totalPages = Math.ceil(filteredInquiries.length / ITEMS_PER_PAGE);
  const paginatedInquiries = filteredInquiries.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  const handleDelete = async (inquiryId) => {
    try {
      await deleteDoc(doc(db, "contact_inquiries", inquiryId));
      setInquiries(inquiries.filter((inq) => inq.id !== inquiryId));
      setDeleteConfirm(null);
      setExpandedInquiry(null);
      alert("Inquiry deleted successfully");
    } catch (error) {
      console.error("Error deleting inquiry:", error);
      alert("Error deleting inquiry. Please try again.");
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-50 pt-20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-primary mx-auto"></div>
            <p className="mt-2 text-gray-600">Loading inquiries...</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 pt-20 pb-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Header */}
        <div className="mb-8">
          <h1 className="text-3xl font-bold text-gray-900">
            Contact Inquiries
          </h1>
          <p className="mt-2 text-gray-600">
            Manage and respond to customer inquiries
          </p>
        </div>

        {/* Filters */}
        <div className="mb-6 grid grid-cols-1 md:grid-cols-3 gap-4">
          <div className="relative">
            <input
              type="text"
              placeholder="Search inquiries..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-primary focus:border-transparent"
            />
            <MagnifyingGlassIcon className="absolute right-3 top-2.5 h-5 w-5 text-gray-400" />
          </div>

          <select
            value={filterStatus}
            onChange={(e) => setFilterStatus(e.target.value)}
            className="px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-primary focus:border-transparent"
          >
            <option value="all">All Status</option>
            <option value="new">New</option>
            <option value="reviewed">Reviewed</option>
          </select>

          <select
            value={`${sortBy}-${sortOrder}`}
            onChange={(e) => {
              const [newSortBy, newSortOrder] = e.target.value.split("-");
              setSortBy(newSortBy);
              setSortOrder(newSortOrder);
            }}
            className="px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-primary focus:border-transparent"
          >
            <option value="timestamp-desc">Newest First</option>
            <option value="timestamp-asc">Oldest First</option>
            <option value="name-asc">Name A-Z</option>
            <option value="name-desc">Name Z-A</option>
          </select>
        </div>

        <div className="mt-12 bg-white rounded-lg shadow overflow-hidden">
          <div className="divide-y divide-gray-200">
            {paginatedInquiries.map((inquiry) => (
              <div key={inquiry.id}>
                <div className="p-6 hover:bg-gray-50">
                  <div
                    className="flex items-center justify-between cursor-pointer"
                    onClick={() =>
                      setExpandedInquiry(
                        expandedInquiry === inquiry.id ? null : inquiry.id
                      )
                    }
                  >
                    <div className="flex items-center space-x-3">
                      <div className="flex-shrink-0">
                        <div className="h-10 w-10 rounded-full bg-primary/10 flex items-center justify-center">
                          <ChatBubbleLeftIcon className="h-5 w-5 text-primary" />
                        </div>
                      </div>
                      <div>
                        <p className="text-sm font-medium text-gray-900">
                          {inquiry.name}
                        </p>
                        <p className="text-sm text-gray-500">
                          {inquiry.timestamp?.toLocaleDateString()} at{" "}
                          {inquiry.timestamp?.toLocaleTimeString()}
                        </p>
                      </div>
                    </div>
                    <div className="flex items-center space-x-2">
                      <span
                        className={`px-3 py-1 rounded-full text-xs font-medium
                        ${
                          inquiry.status === "new"
                            ? "bg-green-100 text-green-800"
                            : "bg-gray-100 text-gray-800"
                        }`}
                      >
                        {inquiry.status === "new" ? "New" : "Reviewed"}
                      </span>
                    </div>
                  </div>

                  {expandedInquiry === inquiry.id && (
                    <div className="mt-6 pl-13">
                      <div className="bg-gray-50 p-6 rounded-lg space-y-6">
                        <div className="flex justify-end gap-2">
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              setDeleteConfirm(inquiry.id);
                            }}
                            className="flex items-center gap-2 px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 transition duration-300"
                          >
                            <TrashIcon className="h-5 w-5" />
                            Delete
                          </button>
                        </div>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                          <div className="space-y-4">
                            <div className="flex items-center space-x-2">
                              <EnvelopeIcon className="h-5 w-5 text-gray-400" />
                              <span className="text-gray-600">
                                {inquiry.email}
                              </span>
                            </div>
                            <div className="flex items-center space-x-2">
                              <PhoneIcon className="h-5 w-5 text-gray-400" />
                              <span className="text-gray-600">
                                {inquiry.phone}
                              </span>
                            </div>
                            <div className="flex items-center space-x-2">
                              <ClockIcon className="h-5 w-5 text-gray-400" />
                              <span className="text-gray-600">
                                {inquiry.timestamp?.toLocaleString()}
                              </span>
                            </div>
                          </div>
                          <div className="bg-white p-4 rounded-lg">
                            <h4 className="font-medium text-gray-900 mb-2">
                              Message
                            </h4>
                            <p className="text-gray-600 whitespace-pre-wrap">
                              {inquiry.message}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {deleteConfirm === inquiry.id && (
                    <div
                      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
                      onClick={(e) => {
                        e.stopPropagation();
                        setDeleteConfirm(null);
                      }}
                    >
                      <div
                        className="bg-white p-6 rounded-lg shadow-xl max-w-md mx-4"
                        onClick={(e) => e.stopPropagation()}
                      >
                        <h3 className="text-xl font-bold mb-4">
                          Confirm Delete
                        </h3>
                        <p className="mb-6 text-gray-600">
                          Are you sure you want to delete this contact inquiry
                          from {inquiry.name}? This action cannot be undone.
                        </p>
                        <div className="flex justify-end gap-4">
                          <button
                            onClick={() => setDeleteConfirm(null)}
                            className="px-4 py-2 border border-gray-300 rounded-lg hover:bg-gray-50"
                          >
                            Cancel
                          </button>
                          <button
                            onClick={() => handleDelete(inquiry.id)}
                            className="px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600"
                          >
                            Delete
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Pagination */}
        <div className="mt-6 flex justify-center space-x-2">
          {[...Array(totalPages)].map((_, i) => (
            <button
              key={i + 1}
              onClick={() => setCurrentPage(i + 1)}
              className={`px-4 py-2 border rounded-lg ${
                currentPage === i + 1 ? "bg-primary text-white" : ""
              }`}
            >
              {i + 1}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}

export default AdminContactInquiries;
