import React, { useState, useEffect, useRef } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import {
  MapPinIcon,
  PencilIcon,
  ArrowRightIcon,
} from "@heroicons/react/24/outline";

function AddressStreetView() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [address] = useState(searchParams.get("address") || "");
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const mapRef = useRef(null);
  const panoramaRef = useRef(null);
  const mapContainerRef = useRef(null);
  const panoContainerRef = useRef(null);

  useEffect(() => {
    if (!process.env.REACT_APP_GOOGLE_MAPS_API_KEY) {
      setError("Google Maps API key is missing");
      setIsLoading(false);
      return;
    }

    // Create a global callback functionhh
    window.initializeGoogleMaps = () => {
      try {
        if (!mapContainerRef.current || !panoContainerRef.current) {
          console.error("Map containers not found");
          return;
        }

        const defaultLocation = { lat: 37.7749, lng: -122.4194 }; // San Francisco

        mapRef.current = new window.google.maps.Map(mapContainerRef.current, {
          center: defaultLocation,
          zoom: 14,
          streetViewControl: false,
        });

        panoramaRef.current = new window.google.maps.StreetViewPanorama(
          panoContainerRef.current,
          {
            position: defaultLocation,
            pov: { heading: 165, pitch: 0 },
            zoom: 1,
          }
        );

        mapRef.current.setStreetView(panoramaRef.current);

        if (address) {
          handleAddressSearch(address);
        }

        setIsLoading(false);
      } catch (err) {
        console.error("Map initialization error:", err);
        setError("Error initializing map");
        setIsLoading(false);
      }
    };

    // Load Google Maps API
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places&callback=initializeGoogleMaps`;
    script.async = true;
    script.defer = true;
    script.onerror = () => {
      setError("Failed to load Google Maps API");
      setIsLoading(false);
    };

    document.head.appendChild(script);

    // Cleanup
    return () => {
      if (window.initializeGoogleMaps) {
        delete window.initializeGoogleMaps;
      }
      const scripts = document.querySelectorAll(
        'script[src*="maps.googleapis.com"]'
      );
      scripts.forEach((script) => script.remove());
    };
  }, [address]);

  const handleAddressSearch = async (searchAddress) => {
    if (
      !searchAddress ||
      !window.google ||
      !mapRef.current ||
      !panoramaRef.current
    ) {
      return;
    }

    try {
      const geocoder = new window.google.maps.Geocoder();

      const results = await new Promise((resolve, reject) => {
        geocoder.geocode({ address: searchAddress }, (results, status) => {
          if (status === "OK" && results?.[0]) {
            resolve(results[0]);
          } else {
            reject(new Error("Address not found"));
          }
        });
      });

      const location = results.geometry.location;
      mapRef.current.setCenter(location);

      const streetViewService = new window.google.maps.StreetViewService();
      const data = await new Promise((resolve, reject) => {
        streetViewService.getPanorama(
          { location, radius: 50 },
          (data, status) => {
            if (status === "OK") {
              resolve(data);
            } else {
              reject(new Error("Street View not available for this location"));
            }
          }
        );
      });

      panoramaRef.current.setPosition(data.location.latLng);
      setError(null);
    } catch (err) {
      setError(err.message);
    }
  };

  const handleEdit = () => {
    navigate("/sell-your-home", {
      state: {
        step: 1,
        address: address,
      },
    });
  };

  const handleNext = () => {
    navigate("/sell-your-home", { state: { step: 2, address: address } });
  };

  return (
    <div className="max-w-7xl mx-auto px-4 py-12 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto text-center mb-12">
        <h1 className="text-4xl font-bold text-gray-900 mb-4">
          Confirm Your Property Location
        </h1>
        <p className="text-xl text-gray-600">
          Please verify that we've found the correct property
        </p>
      </div>

      {error && (
        <div className="max-w-3xl mx-auto mb-8">
          <div className="bg-red-50 border-l-4 border-red-400 p-4 rounded-lg">
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <svg
                  className="h-5 w-5 text-red-400"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zm7-5a1 1 0 00-1 1v3a1 1 0 002 0V10a1 1 0 00-1-1zm0-4a1 1 0 100-2 1 1 0 000 2z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
              <div className="ml-3">
                <p className="text-sm text-red-700">{error}</p>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="max-w-3xl mx-auto mb-8">
        <div className="bg-white rounded-xl shadow-sm p-6 border border-gray-200">
          <div className="flex items-center space-x-3 mb-6">
            <MapPinIcon className="h-6 w-6 text-accent" />
            <div>
              <p className="text-sm text-gray-500">Property Address</p>
              <p className="text-lg font-medium text-gray-900">{address}</p>
            </div>
          </div>

          <div className="flex flex-wrap gap-4">
            <button
              onClick={handleEdit}
              className="inline-flex items-center px-6 py-3 border-2 border-gray-300 text-gray-700 rounded-lg hover:bg-gray-50 transition duration-300 font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
            >
              <PencilIcon className="h-5 w-5 mr-2" />
              Edit Address
            </button>
            <button
              onClick={handleNext}
              disabled={isLoading || error}
              className="inline-flex items-center px-6 py-3 bg-accent text-white rounded-lg hover:bg-opacity-90 transition duration-300 font-medium disabled:opacity-50 disabled:cursor-not-allowed focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-accent"
            >
              Continue
              <ArrowRightIcon className="h-5 w-5 ml-2" />
            </button>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <div className="relative">
          <div
            ref={mapContainerRef}
            className="h-[400px] rounded-xl shadow-lg overflow-hidden"
            style={{ background: "#f0f0f0" }}
          />
          <div className="absolute top-4 left-4 bg-white px-4 py-2 rounded-lg shadow-md">
            <p className="text-sm font-medium text-gray-900">Map View</p>
          </div>
        </div>
        <div className="relative">
          <div
            ref={panoContainerRef}
            className="h-[400px] rounded-xl shadow-lg overflow-hidden"
            style={{ background: "#f0f0f0" }}
          />
          <div className="absolute top-4 left-4 bg-white px-4 py-2 rounded-lg shadow-md">
            <p className="text-sm font-medium text-gray-900">Street View</p>
          </div>
        </div>
      </div>

      {isLoading && (
        <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-8 flex flex-col items-center">
            <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-accent"></div>
            <p className="mt-4 text-gray-600">Loading map...</p>
          </div>
        </div>
      )}
    </div>
  );
}

export default AddressStreetView;
