import React, { useState, useEffect } from "react";
import { collection, getDocs, deleteDoc, doc } from "firebase/firestore";
import { db } from "./firebase";
import jsPDF from "jspdf";
import "jspdf-autotable";

function AdminSellRequests() {
  const [requests, setRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [expandedRequest, setExpandedRequest] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterStatus, setFilterStatus] = useState("all");
  const [sortBy, setSortBy] = useState("createdAt");
  const [sortOrder, setSortOrder] = useState("desc");
  const [deleteConfirm, setDeleteConfirm] = useState(null);

  const ITEMS_PER_PAGE = 10;

  useEffect(() => {
    fetchRequests();
  }, []);

  const fetchRequests = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "homeSellerRequests"));
      const requestsData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setRequests(requestsData);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching requests: ", error);
      setLoading(false);
    }
  };

  const handleDelete = async (requestId) => {
    try {
      await deleteDoc(doc(db, "homeSellerRequests", requestId));
      setRequests(requests.filter((req) => req.id !== requestId));
      setDeleteConfirm(null);
      setExpandedRequest(null);
      alert("Request deleted successfully");
    } catch (error) {
      console.error("Error deleting request:", error);
      alert("Error deleting request. Please try again.");
    }
  };

  if (loading) {
    return <div className="text-center mt-8">Loading...</div>;
  }

  const toggleRequestDetails = (id) => {
    if (expandedRequest === id) {
      setExpandedRequest(null);
    } else {
      setExpandedRequest(id);
    }
  };

  const filteredRequests = requests
    .filter((request) => {
      const matchesSearch =
        request.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        request.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
        request.address.toLowerCase().includes(searchTerm.toLowerCase());

      if (filterStatus === "all") return matchesSearch;
      return matchesSearch && request.sellTimeline === filterStatus;
    })
    .sort((a, b) => {
      const order = sortOrder === "asc" ? 1 : -1;
      if (sortBy === "createdAt") {
        return order * (new Date(a.createdAt) - new Date(b.createdAt));
      }
      return order * (a[sortBy] < b[sortBy] ? -1 : 1);
    });

  const totalPages = Math.ceil(filteredRequests.length / ITEMS_PER_PAGE);
  const paginatedRequests = filteredRequests.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  const generatePDF = (request) => {
    try {
      const doc = new jsPDF();

      // Header
      doc.setFontSize(20);
      doc.setTextColor(44, 62, 80);
      doc.text("Next Home Capital", 20, 20);

      doc.setFontSize(16);
      doc.text("Home Seller Request Details", 20, 30);

      // Timestamp
      doc.setFontSize(10);
      doc.setTextColor(100);
      doc.text(`Generated: ${new Date().toLocaleString()}`, 20, 40);

      // Contact Information
      doc.autoTable({
        startY: 50,
        head: [["Contact Information", ""]],
        body: [
          ["Name", request.name || "N/A"],
          ["Email", request.email || "N/A"],
          ["Phone", request.phone || "N/A"],
          ["Address", request.address || "N/A"],
        ],
        theme: "striped",
        headStyles: {
          fillColor: [44, 62, 80],
          textColor: [255, 255, 255],
        },
        styles: {
          cellPadding: 5,
        },
      });

      // Property Information
      doc.autoTable({
        startY: doc.lastAutoTable.finalY + 10,
        head: [["Property Information", ""]],
        body: [
          ["Home Type", request.homeType || "N/A"],
          ["Property Condition", request.propertyCondition || "N/A"],
          ["Kitchen Condition", request.kitchenCondition || "N/A"],
          ["Bathroom Condition", request.bathroomCondition || "N/A"],
          ["Square Footage", request.squareFootage || "N/A"],
          ["Bedrooms", request.bedrooms || "N/A"],
          ["Bathrooms", request.bathrooms || "N/A"],
        ],
        theme: "striped",
        headStyles: {
          fillColor: [44, 62, 80],
          textColor: [255, 255, 255],
        },
        styles: {
          cellPadding: 5,
        },
      });

      // Status Information
      doc.autoTable({
        startY: doc.lastAutoTable.finalY + 10,
        head: [["Status Information", ""]],
        body: [
          ["Currently Occupied", request.isOccupied || "N/A"],
          ["Listed on Market", request.isListed || "N/A"],
          ["Selling Timeline", request.sellTimeline || "N/A"],
          ["Ownership Duration", request.ownershipDuration || "N/A"],
          ["Mortgage Status", request.mortgageStatus || "N/A"],
        ],
        theme: "striped",
        headStyles: {
          fillColor: [44, 62, 80],
          textColor: [255, 255, 255],
        },
        styles: {
          cellPadding: 5,
        },
      });

      // Additional Details
      doc.autoTable({
        startY: doc.lastAutoTable.finalY + 10,
        head: [["Additional Information", ""]],
        body: [
          ["Reason for Selling", request.sellingReason || "N/A"],
          ["Repairs Needed", request.repairsNeeded || "N/A"],
          ["Consent Given", request.consent ? "Yes" : "No"],
          ["Request Date", new Date(request.createdAt).toLocaleString()],
          ["Additional Notes", request.additionalNotes || "None provided"],
        ],
        theme: "striped",
        headStyles: {
          fillColor: [44, 62, 80],
          textColor: [255, 255, 255],
        },
        styles: {
          cellPadding: 5,
        },
      });

      // Footer
      const pageCount = doc.internal.getNumberOfPages();
      doc.setFontSize(8);
      doc.setTextColor(100);
      for (let i = 1; i <= pageCount; i++) {
        doc.setPage(i);
        doc.text(
          "Confidential - Next Home Capital",
          doc.internal.pageSize.width / 2,
          doc.internal.pageSize.height - 10,
          { align: "center" }
        );
      }

      // Generate filename using seller's name and date
      const sanitizedName = request.name
        ? request.name.replace(/[^a-z0-9]/gi, "_")
        : "unnamed";
      const date = new Date().toISOString().split("T")[0];
      const fileName = `HomeRequest_${sanitizedName}_${date}.pdf`;

      doc.save(fileName);
    } catch (error) {
      console.error("PDF Generation Error:", error);
      alert("Error generating PDF. Check console for details.");
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 pt-20 pb-12">
      <div className="container mx-auto px-4 py-8 max-w-7xl">
        <h1 className="text-3xl font-bold text-gray-800 mb-8">
          Home Seller Requests
        </h1>
        <div className="mb-6 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
          <div className="relative">
            <input
              type="text"
              placeholder="Search requests..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full p-2 border rounded-lg pl-8"
            />
            <svg
              className="w-4 h-4 absolute left-2 top-3 text-gray-500"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
              />
            </svg>
          </div>

          <select
            value={filterStatus}
            onChange={(e) => setFilterStatus(e.target.value)}
            className="p-2 border rounded-lg"
          >
            <option value="all">All Timelines</option>
            <option value="Immediately">Immediately</option>
            <option value="1-3 months">1-3 months</option>
            <option value="3-6 months">3-6 months</option>
            <option value="6+ months">6+ months</option>
          </select>

          <select
            value={sortBy}
            onChange={(e) => setSortBy(e.target.value)}
            className="p-2 border rounded-lg"
          >
            <option value="createdAt">Date Created</option>
            <option value="name">Name</option>
            <option value="sellTimeline">Timeline</option>
          </select>

          <button
            onClick={() => setSortOrder(sortOrder === "asc" ? "desc" : "asc")}
            className="p-2 border rounded-lg flex items-center justify-center gap-2"
          >
            {sortOrder === "asc" ? "↑ Ascending" : "↓ Descending"}
          </button>
        </div>

        <div className="mb-4 text-gray-600">
          Showing {paginatedRequests.length} of {filteredRequests.length}{" "}
          requests
        </div>

        <div className="bg-white rounded-lg shadow overflow-hidden">
          {paginatedRequests.map((request) => (
            <div key={request.id} className="border-b border-gray-200">
              <div
                className="p-6 cursor-pointer hover:bg-gray-50 flex justify-between items-center transition duration-200"
                onClick={() => toggleRequestDetails(request.id)}
              >
                <div className="flex items-center space-x-4">
                  <h2 className="text-xl font-semibold text-gray-800">
                    {request.name}
                  </h2>
                  <span className="text-gray-500">({request.email})</span>
                </div>
                <span className="text-blue-600 text-xl">
                  {expandedRequest === request.id ? "▲" : "▼"}
                </span>
              </div>

              {expandedRequest === request.id && (
                <div className="p-6 bg-gray-50 border-t border-gray-100">
                  <div className="flex justify-end mb-4 gap-2">
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        console.log("Request Data:", request);
                      }}
                      className="px-4 py-2 bg-gray-500 text-white rounded-lg hover:bg-gray-600 transition duration-300"
                    >
                      Log Data
                    </button>

                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        generatePDF(request);
                      }}
                      className="flex items-center gap-2 px-4 py-2 bg-primary text-white rounded-lg hover:bg-primary/90 transition duration-300"
                    >
                      <svg
                        className="w-5 h-5"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                        />
                      </svg>
                      Export to PDF
                    </button>

                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        setDeleteConfirm(request.id);
                      }}
                      className="flex items-center gap-2 px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 transition duration-300"
                    >
                      <svg
                        className="w-5 h-5"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                        />
                      </svg>
                      Delete
                    </button>
                  </div>

                  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                    <div className="bg-white p-4 rounded-lg shadow-sm">
                      <h3 className="text-lg font-semibold mb-3 text-blue-600">
                        Contact Details
                      </h3>
                      <div className="space-y-2">
                        <div>
                          <strong>Email:</strong> {request.email}
                        </div>
                        <div>
                          <strong>Phone:</strong> {request.phone}
                        </div>
                        <div>
                          <strong>Address:</strong> {request.address}
                        </div>
                      </div>
                    </div>
                    <div className="bg-white p-4 rounded-lg shadow-sm">
                      <h3 className="text-lg font-semibold mb-3 text-blue-600">
                        Property Details
                      </h3>
                      <div className="space-y-2">
                        <div>
                          <strong>Home Type:</strong> {request.homeType}
                        </div>
                        <div>
                          <strong>Property Condition:</strong>{" "}
                          {request.propertyCondition}
                        </div>
                        <div>
                          <strong>Kitchen Condition:</strong>{" "}
                          {request.kitchenCondition}
                        </div>
                        <div>
                          <strong>Bathroom Condition:</strong>{" "}
                          {request.bathroomCondition}
                        </div>
                      </div>
                    </div>
                    <div className="bg-white p-4 rounded-lg shadow-sm">
                      <h3 className="text-lg font-semibold mb-3 text-blue-600">
                        Status Details
                      </h3>
                      <div className="space-y-2">
                        <div>
                          <strong>Occupancy:</strong> {request.isOccupied}
                        </div>
                        <div>
                          <strong>Listed Status:</strong> {request.isListed}
                        </div>
                        <div>
                          <strong>Sell Timeline:</strong> {request.sellTimeline}
                        </div>
                        <div>
                          <strong>Ownership Duration:</strong>{" "}
                          {request.ownershipDuration}
                        </div>
                      </div>
                    </div>
                    <div className="bg-white p-4 rounded-lg shadow-sm">
                      <h3 className="text-lg font-semibold mb-3 text-blue-600">
                        Additional Details
                      </h3>
                      <div className="space-y-2">
                        <div>
                          <strong>Selling Reason:</strong>{" "}
                          {request.sellingReason}
                        </div>
                        <div>
                          <strong>Consent Given:</strong>{" "}
                          {request.consent ? "Yes" : "No"}
                        </div>
                        <div>
                          <strong>Created At:</strong>{" "}
                          {new Date(request.createdAt).toLocaleDateString()}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {/* Delete Confirmation Modal */}
              {deleteConfirm === request.id && (
                <div
                  className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
                  onClick={(e) => {
                    e.stopPropagation();
                    setDeleteConfirm(null);
                  }}
                >
                  <div
                    className="bg-white p-6 rounded-lg shadow-xl max-w-md mx-4"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <h3 className="text-xl font-bold mb-4">Confirm Delete</h3>
                    <p className="mb-6 text-gray-600">
                      Are you sure you want to delete this sell request from{" "}
                      {request.name}? This action cannot be undone.
                    </p>
                    <div className="flex justify-end gap-4">
                      <button
                        onClick={() => setDeleteConfirm(null)}
                        className="px-4 py-2 border border-gray-300 rounded-lg hover:bg-gray-50"
                      >
                        Cancel
                      </button>
                      <button
                        onClick={() => handleDelete(request.id)}
                        className="px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600"
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>

        <div className="mt-6 flex justify-center gap-2">
          <button
            onClick={() => setCurrentPage(1)}
            disabled={currentPage === 1}
            className="px-4 py-2 border rounded-lg disabled:opacity-50"
          >
            First
          </button>
          <button
            onClick={() => setCurrentPage(currentPage - 1)}
            disabled={currentPage === 1}
            className="px-4 py-2 border rounded-lg disabled:opacity-50"
          >
            Previous
          </button>

          <div className="flex items-center gap-2">
            {[...Array(Math.min(5, totalPages))].map((_, index) => {
              const pageNumber = currentPage - 2 + index;
              if (pageNumber > 0 && pageNumber <= totalPages) {
                return (
                  <button
                    key={pageNumber}
                    onClick={() => setCurrentPage(pageNumber)}
                    className={`px-4 py-2 border rounded-lg ${
                      currentPage === pageNumber ? "bg-blue-600 text-white" : ""
                    }`}
                  >
                    {pageNumber}
                  </button>
                );
              }
              return null;
            })}
          </div>

          <button
            onClick={() => setCurrentPage(currentPage + 1)}
            disabled={currentPage === totalPages}
            className="px-4 py-2 border rounded-lg disabled:opacity-50"
          >
            Next
          </button>
          <button
            onClick={() => setCurrentPage(totalPages)}
            disabled={currentPage === totalPages}
            className="px-4 py-2 border rounded-lg disabled:opacity-50"
          >
            Last
          </button>
        </div>
      </div>
    </div>
  );
}

export default AdminSellRequests;
