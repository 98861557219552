import React, { useState } from "react";
import { collection, addDoc } from "firebase/firestore";
import { db } from "../../firebase"; // Make sure the path is correct
import { sendNewSellRequestEmail } from "../../utils/emailService";

function AdditionalInfoSection({ formData, updateFormData, onBack }) {
  const [submitStatus, setSubmitStatus] = useState({
    loading: false,
    error: null,
    success: false,
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitStatus({ loading: true, error: null, success: false });

    try {
      // Add timestamp to the form data
      const formDataWithTimestamp = {
        ...formData,
        createdAt: new Date().toISOString(),
      };

      // Add document to 'homeSellerRequests' collection
      const docRef = await addDoc(
        collection(db, "homeSellerRequests"),
        formDataWithTimestamp
      );

      console.log("Document written with ID: ", docRef.id);

      // Send email notification
      try {
        await sendNewSellRequestEmail(formDataWithTimestamp);
      } catch (emailError) {
        console.error("Failed to send email notification:", emailError);
        // Continue with success flow even if email fails
      }

      // Optional: Navigate to a success page or show success message
      setSubmitStatus({
        loading: false,
        error: null,
        success: true,
      });
    } catch (error) {
      console.error("Error submitting form: ", error);
      setSubmitStatus({
        loading: false,
        error: "Failed to submit form. Please try again.",
        success: false,
      });
    }
  };

  if (submitStatus.success) {
    return (
      <div className="text-center py-12">
        <div className="mb-6">
          <svg
            className="mx-auto h-12 w-12 text-green-500"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M5 13l4 4L19 7"
            />
          </svg>
        </div>
        <h2 className="text-3xl font-bold text-primary mb-4">
          Thank You for Your Submission!
        </h2>
        <p className="text-lg text-gray-600 mb-8">
          We've received your information and will contact you soon about your
          property at <span className="font-semibold">{formData.address}</span>.
        </p>
        <p className="text-md text-gray-500">
          If you have any questions, please don't hesitate to contact us.
        </p>
      </div>
    );
  }

  return (
    <div>
      <h2 className="text-2xl font-bold text-primary mb-6">
        Additional Information
      </h2>
      <form onSubmit={handleSubmit} className="space-y-6">
        <div>
          <label
            htmlFor="sellingReason"
            className="block text-sm font-medium text-gray-700 mb-1"
          >
            Why are you selling? (Optional)
          </label>
          <textarea
            id="sellingReason"
            value={formData.sellingReason}
            onChange={(e) => updateFormData({ sellingReason: e.target.value })}
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
            rows="4"
          />
        </div>

        <div>
          <label
            htmlFor="ownershipDuration"
            className="block text-sm font-medium text-gray-700 mb-1"
          >
            How long have you owned the property?
          </label>
          <select
            id="ownershipDuration"
            value={formData.ownershipDuration}
            onChange={(e) =>
              updateFormData({ ownershipDuration: e.target.value })
            }
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
            required
          >
            <option value="">Select duration</option>
            <option value="Less than 1 year">Less than 1 year</option>
            <option value="1-5 years">1-5 years</option>
            <option value="5-10 years">5-10 years</option>
            <option value="10+ years">10+ years</option>
          </select>
        </div>

        <div className="flex items-center">
          <input
            type="checkbox"
            id="consent"
            checked={formData.consent}
            onChange={(e) => updateFormData({ consent: e.target.checked })}
            className="h-4 w-4 text-accent focus:ring-accent border-gray-300 rounded"
            required
          />
          <label htmlFor="consent" className="ml-2 block text-sm text-gray-700">
            I agree to be contacted about my property
          </label>
        </div>

        {submitStatus.error && (
          <div className="text-red-600 text-sm">{submitStatus.error}</div>
        )}

        <div className="flex justify-between">
          <button
            type="button"
            onClick={onBack}
            className="bg-gray-200 text-gray-700 px-8 py-3 rounded-full hover:bg-gray-300 transition duration-300 text-lg font-semibold"
          >
            Back
          </button>
          <button
            type="submit"
            disabled={submitStatus.loading}
            className="bg-accent text-white px-8 py-3 rounded-full hover:bg-opacity-90 transition duration-300 text-lg font-semibold disabled:opacity-50"
          >
            {submitStatus.loading ? "Submitting..." : "Submit"}
          </button>
        </div>
      </form>
    </div>
  );
}

export default AdditionalInfoSection;
