import React from "react";
import {
  HomeIcon,
  ClockIcon,
  BuildingOfficeIcon,
} from "@heroicons/react/24/outline";

function PropertyStatusSection({ formData, updateFormData, onNext, onBack }) {
  const handleSubmit = (e) => {
    e.preventDefault();
    onNext();
  };

  const statusOptions = {
    occupancy: [
      {
        value: "Owner Occupied",
        label: "Owner Occupied",
        description: "You currently live in the property",
      },
      {
        value: "Tenant Occupied",
        label: "Tenant Occupied",
        description: "Property is rented to tenants",
      },
      {
        value: "Vacant",
        label: "Vacant",
        description: "Property is currently empty",
      },
    ],
    listing: [
      {
        value: "Yes",
        label: "Yes",
        description: "Property is currently listed for sale",
      },
      {
        value: "No",
        label: "No",
        description: "Property is not listed for sale",
      },
    ],
    timeline: [
      {
        value: "Immediately",
        label: "Immediately",
        description: "Ready to sell right away",
      },
      {
        value: "1-3 months",
        label: "1-3 months",
        description: "Planning to sell in the next few months",
      },
      {
        value: "3-6 months",
        label: "3-6 months",
        description: "Looking to sell within 6 months",
      },
      {
        value: "6+ months",
        label: "6+ months",
        description: "Planning for the future",
      },
    ],
  };

  return (
    <div className="max-w-2xl mx-auto">
      <div className="text-center mb-10">
        <h2 className="text-3xl font-bold text-gray-900 mb-4">
          Property Status Details
        </h2>
        <p className="text-lg text-gray-600">
          Tell us about the current status of your property
        </p>
      </div>

      <form onSubmit={handleSubmit} className="space-y-8">
        <div className="space-y-6">
          <div>
            <label className="block text-lg font-medium text-gray-900 mb-4">
              Is the property currently occupied?
            </label>
            <div className="space-y-3">
              {statusOptions.occupancy.map((option) => (
                <div
                  key={option.value}
                  className={`relative rounded-lg border-2 p-4 cursor-pointer transition-all duration-200 ${
                    formData.isOccupied === option.value
                      ? "border-accent bg-accent/5"
                      : "border-gray-200 hover:border-accent/50"
                  }`}
                  onClick={() => updateFormData({ isOccupied: option.value })}
                >
                  <div className="flex items-center">
                    <HomeIcon
                      className={`h-6 w-6 mr-3 ${
                        formData.isOccupied === option.value
                          ? "text-accent"
                          : "text-gray-400"
                      }`}
                    />
                    <div>
                      <p className="font-medium text-gray-900">
                        {option.label}
                      </p>
                      <p className="text-sm text-gray-500">
                        {option.description}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div>
            <label className="block text-lg font-medium text-gray-900 mb-4">
              Is the property currently listed?
            </label>
            <div className="space-y-3">
              {statusOptions.listing.map((option) => (
                <div
                  key={option.value}
                  className={`relative rounded-lg border-2 p-4 cursor-pointer transition-all duration-200 ${
                    formData.isListed === option.value
                      ? "border-accent bg-accent/5"
                      : "border-gray-200 hover:border-accent/50"
                  }`}
                  onClick={() => updateFormData({ isListed: option.value })}
                >
                  <div className="flex items-center">
                    <BuildingOfficeIcon
                      className={`h-6 w-6 mr-3 ${
                        formData.isListed === option.value
                          ? "text-accent"
                          : "text-gray-400"
                      }`}
                    />
                    <div>
                      <p className="font-medium text-gray-900">
                        {option.label}
                      </p>
                      <p className="text-sm text-gray-500">
                        {option.description}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div>
            <label className="block text-lg font-medium text-gray-900 mb-4">
              When are you looking to sell?
            </label>
            <div className="space-y-3">
              {statusOptions.timeline.map((option) => (
                <div
                  key={option.value}
                  className={`relative rounded-lg border-2 p-4 cursor-pointer transition-all duration-200 ${
                    formData.sellTimeline === option.value
                      ? "border-accent bg-accent/5"
                      : "border-gray-200 hover:border-accent/50"
                  }`}
                  onClick={() => updateFormData({ sellTimeline: option.value })}
                >
                  <div className="flex items-center">
                    <ClockIcon
                      className={`h-6 w-6 mr-3 ${
                        formData.sellTimeline === option.value
                          ? "text-accent"
                          : "text-gray-400"
                      }`}
                    />
                    <div>
                      <p className="font-medium text-gray-900">
                        {option.label}
                      </p>
                      <p className="text-sm text-gray-500">
                        {option.description}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="flex justify-between pt-8">
          <button
            type="button"
            onClick={onBack}
            className="px-8 py-3 border-2 border-gray-300 text-gray-700 rounded-lg hover:bg-gray-50 transition duration-300 font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
          >
            Back
          </button>
          <button
            type="submit"
            disabled={
              !formData.isOccupied ||
              !formData.isListed ||
              !formData.sellTimeline
            }
            className="px-8 py-3 bg-accent text-white rounded-lg hover:bg-opacity-90 transition duration-300 font-medium disabled:opacity-50 disabled:cursor-not-allowed focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-accent"
          >
            Continue
          </button>
        </div>
      </form>
    </div>
  );
}

export default PropertyStatusSection;
