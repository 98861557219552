import React from "react";

const TimeIcon = ({ time, size = 100, isDarkMode = false }) => {
  const lightColors = {
    background: "#F0F7FF",
    primary: "#007AFF",
    secondary: "#FFA726",
    accent: "#FFD54F",
  };

  const darkColors = {
    background: "#1A3C66",
    primary: "#0A84FF",
    secondary: "#FB8C00",
    accent: "#FFB74D",
  };

  const colors = isDarkMode ? darkColors : lightColors;

  const getMorningIcon = () => (
    <svg width={size} height={size} viewBox="0 0 100 100">
      <circle cx="50" cy="50" r="20" fill={colors.secondary} /> {/* Sun */}
      <path
        d="M50 15 L50 0 M50 85 L50 100 M85 50 L100 50 M15 50 L0 50 M75 25 L85 15 M25 75 L15 85 M75 75 L85 85 M25 25 L15 15"
        stroke={colors.primary}
        strokeWidth="4"
      />
    </svg>
  );

  const getAfternoonIcon = () => (
    <svg width={size} height={size} viewBox="0 0 100 100">
      <circle cx="70" cy="50" r="25" fill={colors.secondary} /> {/* Sun */}
      <path
        d="M20 30 Q50 60 80 30"
        stroke={colors.primary}
        strokeWidth="4"
        fill="none"
      />
    </svg>
  );

  const getEveningIcon = () => (
    <svg width={size} height={size} viewBox="0 0 100 100">
      <circle cx="60" cy="60" r="35" fill={colors.accent} opacity={0.5} />
      <path
        d="M20 40 Q50 70 80 40"
        stroke={colors.primary}
        strokeWidth="4"
        fill="none"
      />
      <path
        d="M30 20 Q50 40 70 20"
        stroke={colors.primary}
        strokeWidth="2"
        fill="none"
      />
    </svg>
  );

  const getIcon = () => {
    const hour = new Date().getHours();
    if (hour < 12) return getMorningIcon();
    if (hour < 18) return getAfternoonIcon();
    return getEveningIcon();
  };

  return getIcon();
};

export default TimeIcon;
