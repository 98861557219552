import React, { useState } from "react";
import { useLocation, Link } from "react-router-dom";
import AddressSection from "./components/sell-steps/AddressSection";
import PropertyDetailsSection from "./components/sell-steps/PropertyDetailsSection";
import ContactSection from "./components/sell-steps/ContactSection.js";
import PropertyStatusSection from "./components/sell-steps/PropertyStatusSection";
import AdditionalInfoSection from "./components/sell-steps/AdditionalInfoSection";
import KitchenConditionSection from "./components/sell-steps/KitchenConditionSection";
import BathroomConditionSection from "./components/sell-steps/BathroomConditionSection";

function SellYourHome() {
  const location = useLocation();
  const [step, setStep] = useState(location.state?.step || 1);
  const [formData, setFormData] = useState({
    address: location.state?.address || "",
    name: "",
    email: "",
    phone: "",
    homeType: "",
    isOccupied: "",
    isListed: "",
    sellTimeline: "",
    ownershipDuration: "",
    sellingReason: "",
    propertyCondition: "",
    consent: false,
    kitchenCondition: "",
    bathroomCondition: "",
  });

  const handleNext = () => {
    setStep(step + 1);
  };

  const handleBack = () => {
    setStep(step - 1);
  };

  const updateFormData = (data) => {
    setFormData((prev) => ({ ...prev, ...data }));
  };

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <AddressSection
            formData={formData}
            updateFormData={updateFormData}
            onNext={handleNext}
          />
        );
      case 2:
        return (
          <PropertyDetailsSection
            formData={formData}
            updateFormData={updateFormData}
            onNext={handleNext}
            onBack={handleBack}
          />
        );
      case 3:
        return (
          <KitchenConditionSection
            formData={formData}
            updateFormData={updateFormData}
            onNext={handleNext}
            onBack={handleBack}
          />
        );
      case 4:
        return (
          <BathroomConditionSection
            formData={formData}
            updateFormData={updateFormData}
            onNext={handleNext}
            onBack={handleBack}
          />
        );
      case 5:
        return (
          <ContactSection
            formData={formData}
            updateFormData={updateFormData}
            onNext={handleNext}
            onBack={handleBack}
          />
        );
      case 6:
        return (
          <PropertyStatusSection
            formData={formData}
            updateFormData={updateFormData}
            onNext={handleNext}
            onBack={handleBack}
          />
        );
      case 7:
        return (
          <AdditionalInfoSection
            formData={formData}
            updateFormData={updateFormData}
            onBack={handleBack}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="bg-gradient-to-br from-gray-50 to-gray-100">
      {/* Hero Section with layered textures */}
      <div className="relative bg-gradient-to-br from-black via-[#111111] to-black">
        {/* Base noise texture - increased opacity */}
        <div
          className="absolute inset-0 opacity-[0.4]"
          style={{
            backgroundImage: `url("data:image/svg+xml,%3Csvg viewBox='0 0 300 300' xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='noiseFilter'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='0.9' numOctaves='4' stitchTiles='stitch'/%3E%3C/filter%3E%3Crect width='100%' height='100%' filter='url(%23noiseFilter)'/%3E%3C/svg%3E")`,
            backgroundRepeat: "repeat",
            mixBlendMode: "soft-light",
          }}
        />

        {/* Diagonal grid - increased opacity and size */}
        <div
          className="absolute inset-0 opacity-[0.15]"
          style={{
            backgroundImage: `url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 60L60 0H30L0 30M60 60V30L30 60' stroke='%23D4AF37' stroke-width='0.8' fill='none' /%3E%3C/svg%3E")`,
            backgroundSize: "60px 60px",
          }}
        />

        {/* Gold dust effect - increased opacity and size */}
        <div
          className="absolute inset-0 opacity-[0.08]"
          style={{
            backgroundImage: `url("data:image/svg+xml,%3Csvg width='80' height='80' viewBox='0 0 80 80' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23D4AF37' fill-opacity='0.5'%3E%3Ccircle cx='40' cy='40' r='0.8'/%3E%3Ccircle cx='20' cy='60' r='0.6'/%3E%3Ccircle cx='60' cy='20' r='0.7'/%3E%3Ccircle cx='10' cy='10' r='0.5'/%3E%3Ccircle cx='70' cy='70' r='0.5'/%3E%3C/g%3E%3C/svg%3E")`,
            backgroundSize: "80px 80px",
          }}
        />

        {/* Depth gradient - adjusted opacity */}
        <div
          className="absolute inset-0 bg-gradient-to-b from-black/60 via-transparent to-black/60"
          style={{
            mixBlendMode: "overlay",
          }}
        />

        <div className="max-w-7xl mx-auto px-4 py-20 sm:px-6 lg:px-8 relative">
          <div className="text-center">
            <h1 className="text-4xl font-bold sm:text-5xl">
              <span className="text-white">Sell Your Home</span>
              <span className="block mt-2 bg-gradient-to-r from-[#D4AF37] to-[#E5C76B] bg-clip-text text-transparent">
                with Confidence
              </span>
            </h1>
            <p className="mt-4 text-xl text-gray-300">
              Get a competitive cash offer in minutes, no obligations
            </p>
          </div>
        </div>
      </div>

      {/* Main Form Section */}
      <div className="max-w-4xl mx-auto px-4 py-8 sm:px-6 lg:px-8 -mt-10">
        <div className="bg-white rounded-xl shadow-xl overflow-hidden border border-[#D4AF37]/10">
          {/* Progress Steps */}
          <div className="px-8 py-6 border-b border-[#D4AF37]/10 bg-gradient-to-r from-gray-50 to-white">
            <div className="flex justify-between items-center">
              {[1, 2, 3, 4, 5, 6, 7].map((stepNumber) => (
                <div key={stepNumber} className="flex flex-col items-center">
                  <div
                    className={`w-10 h-10 rounded-full flex items-center justify-center transition-all duration-300 ${
                      stepNumber === step
                        ? "bg-gradient-to-r from-[#D4AF37] to-[#E5C76B] text-black shadow-lg"
                        : stepNumber < step
                        ? "bg-gradient-to-r from-[#D4AF37] to-[#E5C76B] text-black"
                        : "bg-gray-100 text-gray-400"
                    }`}
                  >
                    {stepNumber < step ? (
                      <svg
                        className="w-6 h-6"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path
                          fillRule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clipRule="evenodd"
                        />
                      </svg>
                    ) : (
                      stepNumber
                    )}
                  </div>
                  <div className="hidden sm:block mt-2 text-xs font-medium text-[#D4AF37]">
                    {getStepLabel(stepNumber)}
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Form Content */}
          <div className="p-8">
            <div className="max-w-3xl mx-auto">{renderStep()}</div>
          </div>
        </div>

        {/* Supporting text section */}
        <div className="mt-8 text-center">
          <p className="text-gray-600">
            Have questions?{" "}
            <Link
              to="/contact"
              className="text-[#D4AF37] hover:text-[#E5C76B] transition-colors duration-300 font-medium"
            >
              Contact our team
            </Link>
          </p>
        </div>
      </div>

      {/* Trust indicators section with matching textures */}
      <div className="relative bg-gradient-to-br from-black via-[#111111] to-black py-16">
        {/* Base noise texture */}
        <div
          className="absolute inset-0 opacity-[0.3]"
          style={{
            backgroundImage: `url("data:image/svg+xml,%3Csvg viewBox='0 0 300 300' xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='noiseFilter'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='0.9' numOctaves='4' stitchTiles='stitch'/%3E%3C/filter%3E%3Crect width='100%' height='100%' filter='url(%23noiseFilter)'/%3E%3C/svg%3E")`,
            backgroundRepeat: "repeat",
            mixBlendMode: "overlay",
          }}
        />

        {/* Subtle diagonal lines */}
        <div
          className="absolute inset-0 opacity-[0.05]"
          style={{
            backgroundImage: `url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 40L40 0H20L0 20M40 40V20L20 40' stroke='%23D4AF37' stroke-width='0.5' fill='none' /%3E%3C/svg%3E")`,
            backgroundSize: "40px 40px",
          }}
        />

        {/* Very subtle gold dust effect */}
        <div
          className="absolute inset-0 opacity-[0.02]"
          style={{
            backgroundImage: `url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23D4AF37' fill-opacity='0.3'%3E%3Ccircle cx='30' cy='30' r='0.5'/%3E%3Ccircle cx='15' cy='45' r='0.3'/%3E%3Ccircle cx='45' cy='15' r='0.4'/%3E%3C/g%3E%3C/svg%3E")`,
            backgroundSize: "60px 60px",
          }}
        />

        {/* Depth gradient */}
        <div
          className="absolute inset-0 bg-gradient-to-b from-black/50 via-transparent to-black/50"
          style={{
            mixBlendMode: "overlay",
          }}
        />

        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <h2 className="text-2xl font-semibold bg-gradient-to-r from-[#D4AF37] to-[#E5C76B] bg-clip-text text-transparent">
              Trusted by Homeowners Across Arizona
            </h2>
            <div className="mt-8 grid grid-cols-2 gap-8 md:grid-cols-4">
              {/* Trust indicators would go here */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

// Helper function to get step labels
function getStepLabel(step) {
  const labels = {
    1: "Address",
    2: "Property",
    3: "Kitchen",
    4: "Bathroom",
    5: "Contact",
    6: "Status",
    7: "Additional",
  };
  return labels[step];
}

export default SellYourHome;
