import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { MapPinIcon } from "@heroicons/react/24/outline";

function AddressSection({ formData, updateFormData }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [error, setError] = useState("");
  const [initialLoad, setInitialLoad] = useState(true);

  useEffect(() => {
    if (initialLoad && location.state?.address && !formData.address) {
      updateFormData({
        ...formData,
        address: location.state.address,
      });
      setInitialLoad(false);
    }
  }, [location.state, formData, updateFormData, initialLoad]);

  useEffect(() => {
    console.log("FormData updated:", formData);
  }, [formData]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!formData.address.trim()) {
      setError("Please enter a valid address");
      return;
    }

    navigate(
      `/addressstreetview?address=${encodeURIComponent(formData.address)}`
    );
  };

  const handleClearAddress = () => {
    updateFormData({
      ...formData,
      address: "",
    });
    setError("");
  };

  return (
    <div className="max-w-2xl mx-auto">
      <div className="text-center mb-10">
        <h2 className="text-3xl font-bold text-gray-900 mb-4">
          Where is your property located?
        </h2>
        <p className="text-lg text-gray-600">
          Enter the full address of the property you'd like to sell
        </p>
      </div>

      <form onSubmit={handleSubmit} className="space-y-8">
        <div className="bg-white rounded-xl shadow-sm p-6 border border-gray-100">
          <div className="space-y-4">
            <label
              htmlFor="address"
              className="block text-lg font-medium text-gray-900"
            >
              Property Address
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <MapPinIcon className="h-5 w-5 text-gray-400" />
              </div>
              <input
                type="text"
                id="address"
                value={formData.address || ""}
                onChange={(e) => {
                  updateFormData({
                    ...formData,
                    address: e.target.value,
                  });
                  setError("");
                }}
                className="block w-full pl-10 pr-12 py-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-accent focus:border-transparent transition duration-150"
                placeholder="Enter your full property address"
                required
              />
              {formData.address && (
                <button
                  type="button"
                  onClick={handleClearAddress}
                  className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-400 hover:text-gray-600 transition-colors duration-150"
                  aria-label="Clear address"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
              )}
            </div>
            {error && (
              <p className="mt-2 text-sm text-red-600 flex items-center">
                <svg
                  className="h-4 w-4 mr-1"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path
                    fillRule="evenodd"
                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                    clipRule="evenodd"
                  />
                </svg>
                {error}
              </p>
            )}
          </div>
        </div>

        <div className="flex justify-end">
          <button
            type="submit"
            className="px-8 py-3 bg-accent text-white rounded-lg hover:bg-opacity-90 transition duration-300 font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-accent"
          >
            View Property
          </button>
        </div>
      </form>
    </div>
  );
}

export default AddressSection;
